import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Api} from "../../helpers/api";
import {WelcomeService} from "../../services/welcome.service";
import {MatDialogRef} from "@angular/material/dialog";
import {LoadingService} from "../../services/loading.service";
import {WikimediaService} from "../../services/wikimedia.service";
import {TranslationService} from "../../services/translation.service";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-welcome-night',
  templateUrl: './welcome-night.component.html',
  styleUrls: ['./welcome-night.component.css']
})
export class WelcomeNightComponent implements OnInit {
  welcomeForm: FormGroup = new  FormGroup({
    lang: new FormControl(null),
    theme: new FormControl(null)
  });
  countries: { name: string, code: string, url: URL }[] = [];
  isLoading = false;
  selectedTheme = 'ocean';
  lastSelectedTheme = '';
  customTheme = ""
  lastCustomTheme = "gjporetkqfdl"
  customThemeNiceState = false;
  selectedLang = 'France';
  lastSelectedLang = 'France';
  customThemeNotNiceState = false;
  themes = [
    {
      id: 1,
      name: 'Océan',
      slug: 'ocean',
      image: 'assets/images/ocean.png',
    },
    {
      id: 2,
      name: 'Forêt',
      slug: 'forest',
      image: 'assets/images/fire.png',
    },
    {
      id: 3,
      name: 'Météo',
      slug: 'meteo',
      image: 'assets/images/tempete.png',
    }
  ]

  constructor(private welcomeService: WelcomeService, public dialogRef: MatDialogRef<WelcomeNightComponent>, private loadingService: LoadingService, private wikimediaService: WikimediaService, private translationService: TranslationService, private languageService: LanguageService) {
  }

  ngOnInit() {
    this.getEUCountries();

  }

  selectTheme(theme: string) {
    this.selectedTheme = theme;
  }

  async verifyCustomTheme() {
    this.customThemeNiceState = false;
    this.customThemeNotNiceState = false;
    if(this.customTheme.length > 0) {
      this.loadingService.loading.next(true)

      let retry = 0;
      while (retry < 3) {
        try {
          const response = await this.welcomeService.verifyTheme(this.customTheme)

          if (JSON.parse(response.responseBody).message.toLowerCase().includes('i')) {
            this.customThemeNiceState = true;
            this.customThemeNotNiceState = false;
            this.selectedTheme = this.customTheme;
          } else {
            this.customThemeNiceState = false;
            this.customThemeNotNiceState = true;
          }
          break;
        } catch (e) {
          console.log(e)
        }
        retry++;
      }
    this.loadingService.loading.next(false)
    }
  }

  async onSubmit() {
    this.loadingService.loading.next(true)

    if (this.selectedTheme !== this.lastSelectedTheme) {
      let retry = 0;
      while (retry < 3) {
        try {
          const themes = await this.welcomeService.getTheme(this.selectedTheme)
          const test: string = JSON.parse(themes.responseBody)['color'];
          document.documentElement.style.setProperty('--bs-primary', test);
          document.documentElement.style.setProperty('--mat-stepper-header-selected-state-icon-background-color', test);
          document.documentElement.style.setProperty('--mat-stepper-header-done-state-icon-background-color', test);
          document.documentElement.style.setProperty('--mat-datepicker-calendar-date-selected-state-background-color', test);
          document.documentElement.style.setProperty('--mat-datepicker-toggle-active-state-icon-color', test);
          document.documentElement.style.setProperty('--mat-badge-background-color', test);
          document.documentElement.style.setProperty('--mat-option-selected-state-label-text-color', test);
          document.documentElement.style.setProperty('--mdc-outlined-text-field-caret-color', test);
          document.documentElement.style.setProperty('--mdc-fab-container-color', test);
          document.documentElement.style.setProperty('--mdc-filled-button-container-color', test);
          document.documentElement.style.setProperty('--primary-color', test);
          document.documentElement.style.setProperty('--md-p', test);
          break;
        } catch (e) {
          console.log(e)
        }
        retry++;
      }

      this.wikimediaService.getImagesFromHome(this.selectedTheme)
    }

    if (this.selectedLang !== this.lastSelectedLang) {
      this.wikimediaService.getLang().subscribe(file => {
        this.translationService.getTranslation(file, this.selectedLang).then(result => {
          let content = JSON.parse(JSON.parse(result.responseBody)['color']);
          this.languageService.setNewTranslations(content);
        }).finally(() => {
          this.dialogRef.close();
          this.loadingService.loading.next(false)
          this.dialogRef.close();
          document.querySelector(".noscroll")?.classList.remove("noscroll");
        });
      })
    } else {
      this.dialogRef.close();
      this.loadingService.loading.next(false)
      this.dialogRef.close();
      document.querySelector(".noscroll")?.classList.remove("noscroll");
    }

    this.lastCustomTheme = this.customTheme;
    this.lastSelectedTheme = this.selectedTheme;
    this.lastSelectedLang = this.selectedLang;
  }

  getEUCountries() {

    Api.locales().listCountriesEU().then(languages => {
      languages.countries.forEach(language => {
           this.countries.push({
             name: language.name,
             code: language.code,
             url: Api.avatars().getFlag(language.code)
           });
      });
      this.countries.push({
        name: 'English',
        code: 'US',
        url: Api.avatars().getFlag('US')
      });
    });
  }

}
