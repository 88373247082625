<div class="d-block d-lg-grid align-items-center home-pancake">
  <div [style.grid-column]="pancakeConfig.reverse ? 2 : 1"
       class="d-block d-lg-grid home-sticky"
       style="top: var(--masthead-height);">
    <div class="mx-auto">
      <ng-lottie
        *ngIf="pancakeConfig.isLottie"
        [options]="{path: pancakeConfig.imagePath}"
        [styles]="{maxWidth: '80%'}"
        width="40em">
      </ng-lottie>
      <!--      <div style="max-width: 80%; width: 40em" [id]="pancakeConfig.imgAlt"></div>-->
      <img *ngIf="!pancakeConfig.isLottie" [alt]="pancakeConfig.imgAlt" [src]="pancakeConfig.imagePath" class="w-100">
    </div>
  </div>
  <!--  Pancakes Items-->
  <div *ngFor="let pancake of pancakes"
       [style.grid-column]="pancakeConfig.reverse ? 1 : 2"
       class="home-pancake-items d-flex justify-content-center">
    <div class="col-11 col-xl-8 gap-4 d-flex flex-column">
      <h3 class="lh-base">{{ translate.getTranslation(pancake.title!) }}</h3>
      <p class="fs-5 lh-base text-justify"> {{ translate.getTranslation(pancake.description!) }} </p>
      <ul *ngIf="pancake.listItems">
        <li *ngFor="let item of pancake.listItems"
            class="fs-5">
          {{ item }}
        </li>
      </ul>
      <span *ngIf="!pancake.isUrl">
              <button (click)="openContactDialog()" *ngIf="pancake.buttonText" class="p-4" color="primary"
                      mat-flat-button> {{ pancake.buttonText }} </button>
      </span>

      <span *ngIf="pancake.isUrl">
              <form>
         <button [formaction]="pancake.url"  class="p-4" color="primary"
                 mat-flat-button> {{ pancake.buttonText }} </button>
      </form>
      </span>

    </div>
  </div>
</div>
