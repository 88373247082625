<div class="row" style="margin-top: 9em">
  <div class="row justify-content-center mb-4">
    <div class="col-sm-10 col-md-8 al text-center align-self-center">
      <h2 class="fs-1"> {{ metadata.title }} </h2>
      <p class="mt-4"> {{ metadata.subtitle }} </p>
    </div>
  </div>
  <div class="row m-0 justify-content-center d-flex flex-column">
    <div class="col d-flex justify-content-between align-self-center flex-wrap">
      <section *ngFor="let step of items"
               class="pe-auto p-4 col-12 col-md-6 col-lg-4 col-xl-3 rounded-3 d-flex flex-column align-items-center">
        <img [alt]="step.altImage" [height]="step.imageHeight" [ngSrc]="step.imagePath" [width]="step.imageWidth">
        <h5 class="fw-bold fs-5">
          <span class="fw-bold" style="font-size: 3em;"> {{ step.number }} </span> {{ step.title }}
        </h5>
        <p class="text-gray"> {{ step.description }} </p>
      </section>
    </div>
  </div>
</div>
