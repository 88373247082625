import {ContactModalComponent} from '../../shared/components/contact/contact-modal/contact-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'app-pricing-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobilePricingComponent {

  constructor(private dialog: MatDialog) {
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
}
