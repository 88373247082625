import {SeoService} from '../../services/seo.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  constructor(private seoService: SeoService) {
  }

  ngOnInit() {
    this.seoService.generateTags({
      title: 'Mentions légales',
      description: 'Consultez nos mentions légales pour en savoir plus sur les informations juridiques relatives à notre entreprise, notre site web et nos services. Nous respectons les réglementations en vigueur et nous engageons à protéger vos données personnelles.'
    });
  }
}
