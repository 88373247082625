import {Injectable} from '@angular/core';
import {SnackbarComponent} from '../snackbar/snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) {
  }

  /**
   * Show a snack message at the bottom of the screen
   * @param message
   */
  showMessage(message: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: {
        message
      }
    });
  }
}
