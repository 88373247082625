import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {Api} from '../helpers/api';
import {Models} from 'appwrite';

// export interface Message {
//   mess
// }

@Injectable({providedIn: 'root'})
export class BotService {

  sendMessage(messages: string[]): any {
    return Api.functions().createExecution(
      '65723d72da84553c757c',
      JSON.stringify({messages})
    );
  }
}
