<div class="row">
  <div class="col-sm-12 col-xl-6">
    <div class="row h-100 d-flex align-content-center">
      <div class="col-12">
        <h1 class="lh-sm">{{ title | translate}}</h1>
      </div>
      <div class="col-12 mt-3 mb-4">
        <p>{{ subtitle | translate}}</p>
      </div>
      <div class="col-12" *ngIf="textButton">
        <button
          (click)="openContactDialog()"
          *ngIf="isButton"
          class="p-4"
          color="primary"
          mat-flat-button>
          {{ textButton }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-sm-10 col-md-8 col-xl-6 mt-5 mt-md-0">
    <img
      [alt]="imageAlt"
      [height]="height"
      [src]="imagePath"
      [width]="width"
      style="max-width: inherit; height: fit-content">
    <!--    <ng-lottie-->
    <!--      [options]="{path: '/assets/lottie/rocket-purple.json'}"-->
    <!--      [styles]="{maxWidth: '80%'}"-->
    <!--      width="30em"-->
    <!--    >-->
    <!--    </ng-lottie>-->
  </div>
</div>
