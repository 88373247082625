<div class="row gap-4 justify-content-evenly">
  <div *ngFor="let service of services"
       class="shadow-sm rounded-1 col-11 col-sm-5 col-xl-3 p-4 pb-0 align-items-end"
       style="border-top: 6px solid var(--bs-primary)">
    <div class="row h-25">
      <h4 class="fs-5 fw-semibold"> {{ service.title }} </h4>
      <p class="text-gray fw-light"> {{ service.subtitle }} </p>
    </div>
    <div class="row align-items-end h-75">
      <img
        [alt]="service.imgAlt"
        [height]="service.imgHeight"
        [ngSrc]="service.imgPath"
        [width]="service.imgWidth">
    </div>
  </div>
</div>
