<div class="row justify-content-evenly mt-3">
  <div class="col-11 col-xl-5 p-4">
    <h3 class="fs-4 my-5">{{ title }}</h3>
    <p class="fs-5 lh-base">
      {{ description }}
    </p>
    <a
      *ngIf="buttonText"
      [href]="buttonLink"
      class="p-4 text-decoration-none"
      color="primary"
      mat-flat-button>
      {{ buttonText }}
    </a>
  </div>
  <div class="col-5 col-xl-2 d-flex align-content-center">
    <img
      [alt]="imgAlt"
      [height]="imgHeight"
      [ngSrc]="imgPath"
      [width]="imgWidth"
      class="w-100 h-auto">
  </div>
</div>
