import {AfterViewInit, Component, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Api} from "../../../../helpers/api";
import {Models} from "appwrite";
import {MatDialog} from "@angular/material/dialog";
import {WelcomeNightComponent} from "../../../welcome-night/welcome-night.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  countries: { name: string, code: string, url: URL }[] = [];

  constructor(@Inject(DOCUMENT) private document: Document, private dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.document.addEventListener(
      "scroll",
      () => {
        const navBar: HTMLElement = document.getElementById('mainNav')!;
        if (document.documentElement.scrollTop === 0) {
          navBar.style.boxShadow = '';
        } else {
          navBar.style.boxShadow = '0 3px 3px rgba(0,0,0,.04)';
        }
      },
      {passive: true}
    );
    this.getEUCountries();
  }

  getEUCountries() {
    Api.locales().listCountriesEU().then(countries => {
//      this.countries = countries.countries;
      countries.countries.forEach(country => {
        this.countries.push({
          name: country.name,
          code: country.code,
          url: Api.avatars().getFlag(country.code)
        });
      });
      console.log(this.countries);
    });
  }

  openDialog() {
    this.dialog.open(WelcomeNightComponent, {
      width: '35em',
      height: 'auto',
      disableClose: true,
    });
  }
}
