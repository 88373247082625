import {Component, OnInit} from '@angular/core';
import anime from 'animejs/lib/anime.es.js';
import {Pancake, PancakeConfig} from '../../shared/components/pancake/pancake.component';

@Component({
  selector: 'app-more-information',
  templateUrl: './more-information.component.html',
  styleUrls: ['./more-information.component.scss']
})
export class MoreInformationComponent implements OnInit {

  technos1 = [
    {
      imgPath: '/assets/icons/angular.svg',
      imgAlt: 'Angular Logo',
      imgWidth: 250,
      imgHeight: 250
    },
    {
      imgPath: '/assets/icons/pwa.svg',
      imgAlt: 'PWA Angular Logo',
      imgWidth: 186,
      imgHeight: 200
    },
    {
      imgPath: '/assets/icons/built-with-appwrite.svg',
      imgAlt: 'Appwrite Logo',
      imgWidth: 384,
      imgHeight: 384
    },
    {
      imgPath: '/assets/icons/HTML5_Logo.svg',
      imgAlt: 'HTML5 Logo',
      imgWidth: 512,
      imgHeight: 512
    },
    {
      imgPath: '/assets/icons/CSS3_logo.svg',
      imgAlt: 'CSS Logo',
      imgWidth: 363,
      imgHeight: 512
    },
    {
      imgPath: '/assets/icons/JavaScript_logo.svg',
      imgAlt: 'JS Logo',
      imgWidth: 630,
      imgHeight: 630
    },
    {
      imgPath: '/assets/icons/angular.svg',
      imgAlt: 'Angular Logo',
      imgWidth: 250,
      imgHeight: 250
    },
    {
      imgPath: '/assets/icons/pwa.svg',
      imgAlt: 'PWA Angular Logo',
      imgWidth: 186,
      imgHeight: 200
    },
  ];

  technos2 = [
    {
      imgPath: '/assets/icons/shopify_glyph.svg',
      imgAlt: 'Shopify Logo',
      imgWidth: 110,
      imgHeight: 125
    },
    {
      imgPath: '/assets/icons/woocommerce-logo-color-black.svg',
      imgAlt: 'Woocommerce Logo',
      imgWidth: 780,
      imgHeight: 180
    },
    {
      imgPath: '/assets/icons/bootstrap-logo.svg',
      imgAlt: 'Bootstrap Logo',
      imgWidth: 512,
      imgHeight: 408
    },
    {
      imgPath: '/assets/icons/php-logo.svg',
      imgAlt: 'PHP Logo',
      imgWidth: 100,
      imgHeight: 50
    },
    {
      imgPath: '/assets/icons/js-green.svg',
      imgAlt: 'NodeJs Logo',
      imgWidth: 71,
      imgHeight: 79
    },
    {
      imgPath: '/assets/icons/shopify_glyph.svg',
      imgAlt: 'Shopify Logo',
      imgWidth: 110,
      imgHeight: 125
    },
    {
      imgPath: '/assets/icons/woocommerce-logo-color-black.svg',
      imgAlt: 'Woocommerce Logo',
      imgWidth: 780,
      imgHeight: 180
    },
  ];

  pancakeItemsSEO: Pancake[] = [
    {
      title: 'Campagnes d\'e-mailing',
      description: 'Mettez en place des campagnes d\'e-mailing qui reprennent les styles, les produits et les articles de blog de votre site, afin que vos communications soient parfaitement en phase avec la marque.',
    },
    {
      title: 'Améliorez votre présence sociale',
      description:
        'Créez un contenu époustouflant et professionnel pour tous vos réseaux sociaux. Choisissez parmi des centaines de modèles et des filtres, polices et animations uniques.',
    }
  ];

  pancakeConfigSEO: PancakeConfig = {
    imagePath: '/assets/lottie/social.json',
    imgAlt: 'Social',
    isLottie: true
  };

  ngOnInit() {
    anime({
      targets: '.animated-list1',
      easing: 'linear',
      translateX: -1000,
      loop: true, direction: 'alternate',
      duration: 60000
    });
    anime({
      targets: '.animated-list2',
      easing: 'linear',
      translateX: 1000,
      loop: true, direction: 'alternate',
      duration: 60000
    });
  }
}
