import {ContactModalComponent} from './contact-modal/contact-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  constructor(private dialog: MatDialog) {
  }

  openDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '25em',
    });
  }
}
