import {FormReview, FormsService} from '../../../services/forms.service';
import {SnackBarService} from '../../../services/snackbar.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SeoService} from '../../../services/seo.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Models} from 'appwrite';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.scss']
})
export class AddReviewComponent implements OnInit {
  formReview = new FormGroup({
    "companyName": new FormControl(null, Validators.required),
    "website": new FormControl(null, Validators.required),
    "name": new FormControl(null, Validators.required),
    "firstname": new FormControl(null, Validators.required),
    "email": new FormControl(null, [Validators.email, Validators.required]),
    "role": new FormControl(null, Validators.required),
    "textReview": new FormControl(null, [Validators.required, Validators.maxLength(200)]),
  });
  isLoading = false;
  fileToSend: any | null = null;
  isUrlValid = true;

  constructor(
    private formService: FormsService,
    private seoService: SeoService,
    private snackService: SnackBarService,
    private router: Router) {
  }

  ngOnInit() {
    this.seoService.generateTags({
      title: 'Avis',
      description: 'Donnez votre avis sur nos services et aidez-nous à nous améliorer. Utilisez notre formulaire de témoignage pour partager votre expérience avec nous et nous aider à mieux comprendre les besoins de nos clients.'
    });
  }

  onFileSelected(event: any) {
    if (!event.target && !event.target.files[0]) {
      return;
    }
    this.fileToSend = event.target.files[0];
    const preview = URL.createObjectURL(this.fileToSend!);
    document.getElementById('imagePreview')?.setAttribute('src', preview);
  }

  onSubmit() {
    if (!this.formReview.valid) {
      return;
    }

    const form: FormReview = {
      companyName: this.formReview.value.companyName!,
      website: this.formReview.value.website!,
      name: this.formReview.value.name!,
      firstname: this.formReview.value.firstname!,
      role: this.formReview.value.role!,
      email: this.formReview.value.email!,
      textReview: this.formReview.value.textReview!,
    }

    this.formService.addReview(form, this.fileToSend)
      .subscribe({
        next: (val: Models.Document | null) => {
          console.log(val);
          console.log('coucou');
          this.isUrlValid = true;
          if (val) {
            this.snackService.showMessage('Merci pour votre temps 😍');
            this.router.navigate(['/']);
          }
        },
        error: (err) => {
          console.log(err.message);
          if (err.message === 'Invalid document structure: Attribute "website" has invalid format. Value must be a valid URL') {
            this.isUrlValid = false;
          }
        }
      })
  }
}

