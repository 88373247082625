<div class="row">
  <div class="row justify-content-center mb-4">
    <div class="col-sm-10 col-md-8 al text-center align-self-center">
      <h2 class="fs-1">Nos réalisations</h2>
    </div>
  </div>
  <div class="row m-0 justify-content-center d-flex flex-column">
    <div class="col d-flex justify-content-center align-self-center flex-wrap">
      <a *ngFor="let achievement of achievements"
         [href]="achievement.websiteUrl"
         class="shadow border-1 p-4 col-12 col-md-6 col-lg-4 col-xl-3 text-reset text-decoration-none
         rounded-5 align-items-center d-flex flex-column p-5">
        <img [alt]="achievement.altImage" [ngSrc]="achievement.logoUrl" height="200" width="200">
        <h5 class="fw-bold fs-5">
          {{ achievement.title }}
        </h5>
        <p class="text-gray"> {{ achievement.description }} </p>
      </a>
    </div>
  </div>
</div>
