import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  selected: string = 'web';

  solutions = [
    {
      value: 'web',
      viewValue: 'Solution web'
    },
    {
      value: 'mobile',
      viewValue: 'Solution mobile'
    },
    {
      value: 'cloud',
      viewValue: 'Solution cloud'
    },
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoService: SeoService) {
  }

  ngOnInit() {
    if (this.route.snapshot.fragment !== null) {
      this.selected = this.route.snapshot.fragment;
    }

    this.seoService.generateTags({
      title: 'Prix',
      description: 'Vous voulez créer votre site internet, application mobile ? Retrouvez tous nos prix sur cette page.'
    });
  }

  async onClose() {
    await this.router.navigate(['prix'], {fragment: this.selected})
  }

}
