import {Injectable} from "@angular/core";
import {Api} from "../helpers/api";
import {environment} from "../../environments/environment";

@Injectable({providedIn: "root"})
export class WelcomeService {


  getTheme(prompt: string) {
    return Api.functions().createExecution(environment.themeFunction, JSON.stringify({
      prompt
    }))
  }

  verifyTheme(prompt: string) {
    console.log(prompt)
    return Api.functions().createExecution(environment.verifyThemeFunction, prompt)
  }
}
