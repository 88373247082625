import {ContactModalComponent} from '../../shared/components/contact/contact-modal/contact-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Component} from '@angular/core';

@Component({
  selector: 'app-pricing-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.css']
})
export class WebPricingComponent {
  selected = 0;

  constructor(private dialog: MatDialog) {
  }

  onChangeTab(index: number) {
    this.selected = index;
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
}
