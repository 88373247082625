<div class="container" style="padding-top: 8em">
  <app-big-hero
    [isButton]="true"
    buttonLink="/"
    imagePath="/assets/images/mobile.svg"
    [height]="596"
    [width]="718"
    imageAlt="Jeune femme qui développe une application mobile."
    subtitle="Donnez vie à vos idées avec des applications mobiles sur mesure."
    title="Créez une App de haute qualité">
  </app-big-hero>
  <app-workflow
    [items]="workflowItems"
    [metadata]="workflowData">
  </app-workflow>
</div>
<div class="container-fluid" style="margin-top: 9em">
  <div class="row">
    <div class="row justify-content-center mb-4">
      <div class="col-sm-10 col-md-8 al text-center align-self-center">
        <h2 class="fs-1">Une solution d'applications pour chaque projet</h2>
        <p class="mt-4">Développez des applications mobiles sur mesure pour vos objectifs commerciaux.</p>
      </div>
    </div>
    <app-service-list
      [services]="services">
    </app-service-list>
  </div>
</div>
<div class="container" style="margin-top: 7em">
  <div class="row">
    <div class="row justify-content-center mb-4">
      <div class="col-sm-10 col-md-8 al text-center align-self-center">
        <h2 class="fs-1">Intégrations illimitées</h2>
        <p class="mt-4">Connectez votre App mobile à votre API, utilisez vos bases de données pour la rendre dynamique
          !</p>
      </div>
    </div>
    <div class="row justify-content-evenly gap-5">
      <div
        *ngFor="let integration of integrations"
        class="d-flex flex-column w-auto text-center">
        <img [alt]="integration.imgAlt"
             [ngSrc]="integration.imgPth"
             class="p-4 bg-tertiary rounded-circle"
             height="128"
             width="128">
        <label class="text-tertiary fw-medium mt-3"> {{ integration.legend }} </label>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid gap-5 m-0">
  <div class="row flex-column flex-column gap-5">
    <div class="col">
      <app-pancake
        [pancakeConfig]="storeConfig"
        [pancakes]="storePancake">
      </app-pancake>
    </div>
    <div class="col">
      <app-achievement
        [achievements]="achievements">
      </app-achievement>
    </div>
    <div class="col">
      <app-faq
        [faqItems]="faqItems">
      </app-faq>
    </div>
  </div>
</div>
