import {WorkflowData, WorkflowItem} from '../shared/components/workflow/workflow.component';
import {Pancake, PancakeConfig} from '../shared/components/pancake/pancake.component';
import {Achievement} from '../shared/components/achievement/achievement.component';
import {FaqItems} from '../shared/components/faq/faq.component';
import {SeoService} from '../../services/seo.service';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "../../services/language.service";

interface HostItem {
  imagePath: string;
  imageAlt: string;
  link: string;
}

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
  // workflowItems: WorkflowItem[] = [
  //   {
  //     number: '1.',
  //     title: this.translate.instant('subtitle'),
  //     description: 'Nous travaillons ensemble afin de convenir ensemble d\'un design qui vous convienne.',
  //     imagePath: './assets/images/design_team.svg',
  //     imageWidth: 656,
  //     imageHeight: 482,
  //     altImage: 'Proposition de design'
  //   },
  //   {
  //     number: '2.',
  //     title: 'Choisissez votre contenu',
  //     description: 'Ajouter vos propres contenus (images, vidéos…) pour personnaliser votre site internet.',
  //     imagePath: './assets/images/content_creator.svg',
  //     imageWidth: 766,
  //     imageHeight: 517,
  //     altImage: 'Ajout de contenue'
  //   },
  //   {
  //     number: '3.',
  //     title: 'Publiez votre site internet',
  //     description: 'Nous nous occupons de tout ! Dites-nous simplement où vous voulez héberger votre site internet.',
  //     imagePath: './assets/images/publish.svg',
  //     imageWidth: 847,
  //     imageHeight: 589,
  //     altImage: 'Publication d\'un poste'
  //   }
  // ];

  workflowData: WorkflowData = {
    title: 'Créez votre site internet en trois étapes',
    subtitle: 'Voyez grand pour votre entreprise.'
  }

  hostItems: HostItem[] = [
    {
      imagePath: './assets/images/ovh.webp',
      imageAlt: 'OVH Cloud logo',
      link: 'https://www.ovhcloud.com/fr/'

    },
    {
      imagePath: './assets/images/IONOS.webp',
      imageAlt: 'IONOS logo',
      link: 'https://www.ionos.fr/'
    },
    {
      imagePath: './assets/images/digital.webp',
      imageAlt: 'Servers',
      link: 'https://www.digitalocean.com/'
    },
  ]

  faqItems: FaqItems[] = [
    {
      question: "Comment effectuer une commande ?",
      answer: "Vous pouvez utiliser le formulaire de contact situé sur la droite de votre écran."
    },
    {
      question: "Dois-je utiliser un générateur de site internet ou faire appel à un développeur Web ?",
      answer: "Les logiciels qui permettent de créer des sites internets en quelques cliques sont des outils simple et rapide à prendre en main. " +
        "Cependant si vous recherchez des fonctionnalités avancées (formulaire de contact, base de donnée utilisateurs…) ces outils ne pourront pas répondre efficacement à vos attentes."
    },
    {
      question: "Ai-je besoin d'un nom de domaine ?",
      answer: "Oui, chaque site web sur Internet a besoin de son propre nom de domaine pour que les visiteurs puissent" +
        " le trouver. Un nom de domaine personnalisé peut donner à votre site web un aspect professionnel en ligne. " +
        "Il renforce votre marque, rend votre site internet plus mémorable et aide les visiteurs à vous trouver en ligne."
    },
    {
      question: "Quelles sont nos CGV ?",
      answer: "Suivez simplement ce lien:"
    },
    {
      question: 'Qu\'est-ce qu\'un score SEO ?',
      answer: 'Le score de référencement du site internet est une mesure de l\'efficacité avec laquelle les ' +
        'éléments techniques et les éléments liés à l\'utilisateur d\'un site internet contribuent à son optimisation ' +
        'pour les moteurs de recherche. Ce score, classé sur une échelle de 100, est basé sur quatre facteurs clés : ' +
        'les aspects techniques, le contenu, l\'expérience utilisateur et l\'optimisation mobile.'
    }
  ]

  pancakeItemsSEO: Pancake[] = [
    {
      title: this.translate.getTranslation('nuclear11'),
      description: this.translate.getTranslation('nuclearintro'),
    },
    {
      title: 'nuclearfin',
      description:
        'nuclearconc',
    }
  ]

  pancakeConfigSEO: PancakeConfig = {
    imagePath: '/assets/lottie/nuclear.json',
    imgAlt: 'seo',
    isLottie: true
  }

  pancakeItemsAnalytics: Pancake[] = [
  ]

  pancakeConfigAnalytics: PancakeConfig = {
    imagePath: '/assets/lottie/analytics.json',
    imgAlt: 'Analytics',
    isLottie: true,
    reverse: true
  }

  achievements: Achievement[] = [
  ]

  constructor(private seoService: SeoService, public translate: LanguageService) {
  }

  ngOnInit() {
    this.seoService.generateTags({
      title: 'Site internet',
      description: 'Nous concevons des sites internet esthétiques et fonctionnels qui répondent aux besoins uniques de chaque entreprise. Découvrez notre portfolio de sites internets et contactez-nous pour discuter de votre projet.'
    });
  }
}
