import {Component, Input} from '@angular/core';
import {ContactModalComponent} from '../contact/contact-modal/contact-modal.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-big-hero',
  templateUrl: './big-hero.component.html',
  styleUrls: ['./big-hero.component.scss']
})
export class BigHeroComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() isButton: boolean = false;
  @Input() textButton?: string = '';
  @Input() buttonLink?: string = '';
  @Input() imagePath!: string;
  @Input() imageAlt: string = '';
  @Input() width!: number;
  @Input() height!: number;

  constructor(private dialog: MatDialog) {
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
}
