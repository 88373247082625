import {Component} from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {

  values: { title: string, text: string } [] = [
    {
      title: 'OPEN-SOURCE',
      text: 'Les logiciels libres favorisent le développement de l\'internet en tant que ressource publique.'
    },
    {
      title: 'Transparence',
      text: 'Des processus communautaires transparents favorisent la participation, la responsabilité et la confiance.'
    },
    {
      title: 'Épanouissement',
      text: 'L\'internet doit enrichir la vie de chaque être humain.'
    }
  ]

  supporters = [
    {
      imgLink: 'https://www.w3schools.com/images/w3schools120x90.gif',
      alt: 'w3 School logo',
      urlLink: 'https://www.w3schools.com/',
      width: 120,
      height: 90
    },
    {
      imgLink: 'https://fedoraproject.org/assets/images/logos/fedora-blue.png',
      alt: 'Logo de Fedora',
      urlLink: 'https://fedoraproject.org/',
      width: 450,
      height: 131
    },
    {
      imgLink: 'assets/icons/built-with-appwrite.svg',
      alt: 'Logo de Appwrite',
      urlLink: 'https://appwrite.io/',
      width: 384,
      height: 384
    },
    {
      imgLink: 'assets/images/nextcloud_logo.webp',
      alt: 'Logo de Nextcloud',
      urlLink: 'https://nextcloud.com/',
      width: 200,
      height: 200
    }
  ]
}
