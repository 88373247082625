import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  @Input() title: string;
  @Input({required: true}) description: string;
  @Input({required: true}) imgPath: string;
  @Input({required: true}) imgAlt: string;
  @Input({required: true}) imgWidth: number;
  @Input({required: true}) imgHeight: number;
  @Input() buttonText: string
  @Input() buttonLink: string
}
