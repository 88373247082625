import {ContactModalComponent} from '../contact/contact-modal/contact-modal.component';
import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as lottie from 'lottie-web';
import {LanguageService} from "../../../../services/language.service";

export interface Pancake {
  title: string;
  description?: string;
  buttonText?: string;
  listItems?: string[]
  isUrl?: boolean,
  url?:string
}

export interface PancakeConfig {
  imagePath: string;
  imgAlt: string;
  isLottie?: boolean;
  reverse?: boolean;
}

@Component({
  selector: 'app-pancake',
  templateUrl: './pancake.component.html',
  styleUrls: ['./pancake.component.scss']
})
export class PancakeComponent implements OnInit {
  @ViewChild('lottieContainer') lottieEl: ElementRef;
  @Input() pancakes: Pancake[] = [];
  @Input({required: true}) pancakeConfig: PancakeConfig;

  constructor(private dialog: MatDialog, public translate: LanguageService) {
  }

  ngOnInit() {
    // lottie.loadAnimation({
    //   container: element, // the dom element that will contain the animation
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   path: 'data.json' // the path to the animation json
    // });
    if (this.pancakeConfig.isLottie) {
      lottie.default.loadAnimation(
        {
          container: document.getElementById('seot')!, // the dom element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: this.pancakeConfig.imagePath // the path to the animation json
        }
      )
    }
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
}
