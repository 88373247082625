<div class="container" style="padding-top: 8em">
  <main class="col-12 col-md-10 col-xl-8 mx-auto">
    <div class="row align-content-center mx-auto">
      <div class="col-12 col-md-10 col-xl-5 text-center align-content-center mx-auto">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Choisissez une solution</mat-label>
          <mat-select (openedChange)="onClose()" [(value)]="selected">
            <mat-option *ngFor="let solution of solutions" [value]="solution.value">
              {{solution.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div [ngSwitch]="selected">
      <app-pricing-web *ngSwitchCase="'web'"></app-pricing-web>
      <app-pricing-mobile *ngSwitchCase="'mobile'"></app-pricing-mobile>
      <app-pricing-cloud *ngSwitchCase="'cloud'"></app-pricing-cloud>
    </div>
  </main>
</div>
