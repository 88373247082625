<h2 mat-dialog-title>Nous contacter</h2>
<form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
  <mat-dialog-content>
    <div class="row mt-1">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Nom</mat-label>
          <input
            formControlName="name"
            matInput
            type="text">
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class=" w-100">
          <mat-label>Prénom</mat-label>
          <input
            formControlName="firstname"
            matInput
            type="text"
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Adresse Email</mat-label>
          <input
            formControlName="email"
            matInput
            type="email">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-between">
    <div>
      <button mat-button mat-dialog-close="" type="button">Annuler</button>
    </div>
    <div>
      <button [disabled]="!contactForm.valid || isLoading" mat-button type="submit">
        <div *ngIf="isLoading" class="spinner-border spinner-border-sm mx-1" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span class="spinner lab">Envoyer</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
