<div class="container" style="padding-top: 8em">
  <app-big-hero
    imagePath=""

    title="Le nuclaire"
    [width]="876"
    imageAlt="">
  </app-big-hero>

  <div class="row mt-5 justify-content-center">

  </div>
</div>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-8 text-center">
      <h3 class="text-center">{{translate.getTranslation('nuclear11')}}</h3>
      <p>{{translate.getTranslation('nuclearintro')}}</p>
<!--      <app-pancake-->
<!--        [pancakeConfig]="pancakeConfigSEO"-->
<!--        [pancakes]="pancakeItemsSEO">-->
<!--      </app-pancake>-->
    </div>
<!--    <div class="col">-->
<!--      <app-pancake-->
<!--        [pancakeConfig]="pancakeConfigAnalytics"-->
<!--        [pancakes]="pancakeItemsAnalytics">-->
<!--      </app-pancake>-->
<!--    </div>-->
  </div>
</div>
