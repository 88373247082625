import {Component} from '@angular/core';
import {ContactModalComponent} from '../contact/contact-modal/contact-modal.component';
import {MatDialog} from '@angular/material/dialog';

interface NavigationSections {
  sectionName: string;
  links: {
    text: string,
    hrefAttribute?: string,
    openContactDialog?: boolean,
    routerAttribute?: string
  }[]
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  sections: NavigationSections[] = [
    {
      sectionName: 'Contact',
      links: [
        {
          text: 'xx xx xx xx',
          hrefAttribute: 'tel:+xxxxxxxx'
        },
        {
          text: 'contacts@iaduclimat.green',
          hrefAttribute: 'mailto:#'
        },
        {
          text: 'ia du climat',
          hrefAttribute: '/'
        },
        {
          text: 'Nous Contacter',
          openContactDialog: true
        },
      ]
    },
    {
      sectionName: 'Navigation',
      links: [
        {
          text: 'Accueil',
          routerAttribute: '/'
        },
        {
          text: 'Le nuclaire',
          routerAttribute: '/nucleaire'
        },
        {
          text: 'Les transports',
          routerAttribute: '/transports'
        },
        {
          text: 'Sitographie',
          routerAttribute: '/sitographie'
        }
      ]
    },
    {
      sectionName: 'Légal',
      links: [
        {
          text: 'Mentions légales',
          routerAttribute: '/mentions-legales'
        },
      ]
    }
  ];

  constructor(private dialog: MatDialog) {
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
}
