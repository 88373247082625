import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormsService} from '../../../../../services/forms.service';
import {SnackBarService} from '../../../../../services/snackbar.service';
import {MatDialogRef} from '@angular/material/dialog';
import Swiper from 'swiper';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss']
})
export class ContactModalComponent implements AfterViewInit {

  @ViewChild('swiperEl')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  toppingList: string[] = ['Site-Web', 'App mobile', 'Cloud', 'Support', 'Autre'];
  isLoading = false;
  contactForm: FormGroup = new FormGroup({
    'name': new FormControl(null, Validators.required),
    'firstname': new FormControl(null, Validators.required),
    'email': new FormControl(null, [Validators.required, Validators.email])
  })

  constructor(
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private snackService: SnackBarService,
    private contactService: FormsService,
  ) {
  }

  ngAfterViewInit() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.cd.detectChanges();
  }

  onSlidePrev() {
    this.swiper?.slidePrev();
  }

  onSlideNext() {
    this.swiper?.slideNext();
  }

  onSubmit() {
    if (!this.contactForm.valid) {
      return;
    }
    this.isLoading = true;
    this.contactService.sendContactForm(
      {
        subject: 'NO SUBJECT',
        message: 'NO MESSAGE',
        name: this.contactForm.get('name')?.value,
        firstname: this.contactForm.get('firstname')?.value,
        phone_number: 'NO PHONE',
        email: this.contactForm.get('email')?.value
      }
    ).then((res) => {
      let message: string;
      if (res.responseStatusCode === 200) {
        message = 'Mesage envoyé avec succès !'
      } else {
        this.isLoading = false;
        console.error(res)
        message = 'Erreur: ' + res.errors + ' ' + res.responseStatusCode;
      }
      this.snackService.showMessage(message);
    }, (err) => {
      console.log(err);
      this.isLoading = false;
    })
  }
}
