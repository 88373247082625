import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {UserRoutingModule} from './user-routing.module';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LottieModule} from 'ngx-lottie';

import {ContactModalComponent} from './shared/components/contact/contact-modal/contact-modal.component';
import {MoreInformationComponent} from './website/more-information/more-information.component';
import {AchievementComponent} from './shared/components/achievement/achievement.component';
import {ECommerceComponent} from './pricing/web/e-commerce/e-commerce.component';
import {WorkflowComponent} from './shared/components/workflow/workflow.component';
import {ServiceListComponent} from './mobile/service-list/service-list.component';
import {BigHeroComponent} from './shared/components/big-hero/big-hero.component';
import {WebsitePricingComponent} from './pricing/web/website/website.component';
import {ContactComponent} from './shared/components/contact/contact.component';
import {AssistantComponent} from './shared/components/assistant/assistant.component';
import {PancakeComponent} from './shared/components/pancake/pancake.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {AddReviewComponent} from './home/add-review/add-review.component';
import {MobilePricingComponent} from './pricing/mobile/mobile.component';
import {HeroComponent} from './shared/components/hero/hero.component';
import {CloudPricingComponent} from './pricing/cloud/cloud.component';
import {WebPricingComponent} from './pricing/web/web.component';
import {FaqComponent} from './shared/components/faq/faq.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {PricingComponent} from './pricing/pricing.component';
import {SnackComponent} from './legal/snack/snack.component';
import {WebsiteComponent} from './website/website.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MobileComponent} from './mobile/mobile.component';
import {LegalComponent} from './legal/legal.component';
import {CloudComponent} from './cloud/cloud.component';
import {MatTabsModule} from '@angular/material/tabs';
import {HomeComponent} from './home/home.component';
import {UserComponent} from './user.component';
import {TranslateModule} from "@ngx-translate/core";
import { WelcomeNightComponent } from './welcome-night/welcome-night.component';
import { LoadingComponent } from './loading/loading.component';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    FaqComponent,
    UserComponent,
    HomeComponent,
    HeroComponent,
    CloudComponent,
    SnackComponent,
    LegalComponent,
    HeaderComponent,
    FooterComponent,
    MobileComponent,
    PricingComponent,
    WebsiteComponent,
    PancakeComponent,
    BigHeroComponent,
    ContactComponent,
    AssistantComponent,
    AboutUsComponent,
    WorkflowComponent,
    AddReviewComponent,
    ECommerceComponent,
    WebPricingComponent,
    AchievementComponent,
    ServiceListComponent,
    ContactModalComponent,
    CloudPricingComponent,
    MobilePricingComponent,
    WebsitePricingComponent,
    MoreInformationComponent,
    WelcomeNightComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatCheckboxModule,
    UserRoutingModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    LottieModule.forRoot({player: playerFactory}),
    TranslateModule,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UserModule {
}
