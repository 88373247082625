import {MatDialog} from '@angular/material/dialog';
import {Component, EventEmitter, Output} from '@angular/core';
import {BotService} from "../../../../services/bot.service";

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.scss']
})
export class AssistantComponent {
  assistantShowModal = false;
  isGptLoading = false;
  messages = [
    {
      from: 'bot',
      message: 'Bonjour ! Je suis Blue votre assistant sur le climat ! Posez moi n\'importe quelle question sur le climat !'
    },
  ]
  userMessage = ''

  constructor(private botService: BotService) {
  }


  toggleAssistantModal() {
    this.assistantShowModal = !this.assistantShowModal;
    // const html = document.querySelector('html');
    // if (!html) return;
    // if (this.assistantShowModal) {
    //   html.style.position = 'fixed';
    //   html.style.overflowY = 'scroll';
    // } else {
    //   html.style.position = '';
    // }
    const b = document.body;
    b.style.setProperty('--st', -(document.documentElement.scrollTop) + "px");
  }

  scrollToBottom() {
    setTimeout(() => {
      const doc = document.querySelector('.messages')
      if (doc) {
        doc.scrollTop = doc.scrollHeight;
      }
    }, 100)
  }

  async sendMessage() {
    if (this.isGptLoading) return;
    try {
      this.isGptLoading = true;

      this.messages.push({
        from: 'user',
        message: this.userMessage
      });
      this.scrollToBottom();

      this.userMessage = '';

        const response = JSON.parse(
          (await this.botService.sendMessage(
            this.messages.map(message => `from ${message.from} : ${message.message}`)
          )
          ).responseBody)

        this.messages.push({
        from: 'bot',
        message: response.message,
      })
    } catch (e) {
      console.log(e)
      this.messages.push({
        from: 'bot',
        message: 'Désolé, je n\'ai pas bien compris, pouvez vous reformuler s\'il vous plaît?'
      })
      return;
    }

    this.scrollToBottom();
    this.isGptLoading = false;
    this.userMessage = '';
  }
}
