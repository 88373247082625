import {Account, Client, Databases, Functions, Storage, Locale, Avatars} from 'appwrite';
import {environment} from '../../environments/environment';

export class Api {
  private static sdk: Client | null;
  private static account: Account;
  private static database: Databases;
  private static function: Functions;
  private static storage: Storage;
  private static local: Locale;
  private static avatar: Avatars;

  static client() {
    if (this.sdk) {
      return this.sdk;
    }

    const client = new Client();
    client
      .setEndpoint(environment.serverEndpoint)
      .setProject(environment.projectId)
      .setLocale('fr-FR');
    this.sdk = client;
    return this.sdk;
  }

  static accounts(): Account {
    if (!this.sdk) {
      this.client();
    }
    if (this.account) {
      return this.account;
    }

    this.account = new Account(this.sdk!);
    return this.account;
  }

  static databases(): Databases {
    if (!this.sdk) {
      this.client();
    }
    if (this.database) {
      return this.database;
    }

    this.database = new Databases(this.sdk!);
    return this.database;
  }

  static functions(): Functions {
    if (!this.sdk) {
      this.client();
    }
    if (this.function) {
      return this.function;
    }

    this.function = new Functions(this.sdk!);
    return this.function;
  }

  static storages(): Storage {
    if (!this.sdk) {
      this.client();
    }
    if (this.storage) {
      return this.storage;
    }

    this.storage = new Storage(this.sdk!);
    return this.storage;
  }

  static locales(): Locale {
    if (!this.sdk) {
      this.client();
    }
    if (this.local) {
      return this.local;
    }

    this.local = new Locale(this.sdk!);
    return this.local;
  }

  static avatars(): Avatars {
    if (!this.sdk) {
      this.client();
    }
    if (this.avatar) {
      return this.avatar;
    }

    this.avatar = new Avatars(this.sdk!);
    return this.avatar;
  }
}
