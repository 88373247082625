<div class="container" style="padding-top: 8em">
  <div class="row d-flex justify-content-center">
    <div class="mt-5 p-3 rounded col-11 col-md-8 d-flex flex-column text-center">
      <div>
        <h3 class="fs-1">Donnez votre avis !</h3>
      </div>
      <div>
        <form (ngSubmit)="onSubmit()" [formGroup]="formReview" class="w-100 mt-5">
          <div class="row d-flex flex-wrap">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nom de votre entreprise</mat-label>
                <input
                  formControlName="companyName"
                  matInput
                  placeholder="Ex: L'IA du Climat">
                <mat-error *ngIf="formReview!.get('companyName')?.hasError('required')">
                  Le nom de l'entreprise est obligatoire
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Site Internet</mat-label>
                <input
                  formControlName="website"
                  matInput
                  placeholder="Ex: https://bugprog.studio">
                <mat-error
                  *ngIf="formReview!.get('website')?.hasError('required') || !isUrlValid">
                  Veuillez renseigner une URL valide
                </mat-error>
              </mat-form-field>
              <p
                *ngIf="!isUrlValid"
                style="color: var(--bs-danger)">
                Veuillez renseigner une URL valide
              </p>
            </div>
          </div>
          <div class="row d-flex flex-wrap mb-5 mb-sm-0">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Nom</mat-label>
                <input
                  formControlName="name"
                  matInput
                  placeholder="Ex: Dupond">
                <mat-error *ngIf="formReview!.get('name')?.hasError('required')">
                  Veuillez mettre votre nom
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Prénom</mat-label>
                <input
                  formControlName="firstname"
                  matInput
                  placeholder="Ex: Dupont">
                <mat-error *ngIf="formReview!.get('firstname')?.hasError('required')">
                  Veuillez mettre votre prénom
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email</mat-label>
                <input
                  formControlName="email"
                  matInput
                  placeholder="Ex: example@example.fr">
                <mat-error *ngIf="formReview!.get('email')?.hasError('required')">
                  Veuillez mettre votre email
                </mat-error>
                <mat-error
                  *ngIf="formReview!.get('email')?.hasError('email') && !formReview!.get('email')?.hasError('required')">
                  Veuillez mettre votre email valide
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Rôle</mat-label>
                <input
                  formControlName="role"
                  matInput
                  placeholder="Ex: Directeur marketing...">
                <mat-error *ngIf="formReview!.get('role')?.hasError('required')">
                  Veuillez mettre un rôle
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12 d-flex">
              <button (click)="fileInput.click()" color="primary" mat-stroked-button type="button">Photo de profil
              </button>
              <input #fileInput (change)="onFileSelected($event)" accept="image/png, image/jpeg" hidden type="file">
              <img
                *ngIf="fileToSend"
                alt="Aperçu photo de profil"
                class="mx-auto d-block rounded-circle"
                id="imagePreview"
                style="height: 70px; width: 70px">
            </div>
          </div>
          <mat-form-field appearance="outline" class="w-100 mt-3">
            <mat-label>Votre avis</mat-label>
            <textarea
              #textReview
              formControlName="textReview"
              matInput style="height: 10em">
            </textarea>
            <mat-hint align="end">{{textReview.value.length}} / 200</mat-hint>
            <mat-error *ngIf="formReview!.get('textReview')?.hasError('required')">
              Veuillez mettre un avis
            </mat-error>
            <mat-error *ngIf="formReview!.get('textReview')?.hasError('maxlength')">
              Max 200 caractères
            </mat-error>
          </mat-form-field>
          <footer class="d-flex p-3">
            <button [disabled]="!formReview.valid || isLoading || !fileToSend" class="ms-auto" color="primary"
                    mat-flat-button
                    type="submit">
              <div *ngIf="isLoading" class="spinner-border spinner-border-sm mx-1" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span class="spinner lab">Envoyer</span>
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</div>
