import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {catchError, throwError} from "rxjs";

@Injectable({providedIn: "root"})
export class WikimediaService {
  homeImages: any[] = [];

  constructor(private http: HttpClient) {}
  getWikiImages(prompt: string) {
    return this.http.get<any>('https://commons.wikimedia.org/w/api.php?action=query&origin=*&generator=search&gsrnamespace=6&gsrsearch=%22'+prompt+'%22&gsrlimit=20&gsroffset=20&prop=imageinfo&iiprop=url&format=json')
      .pipe(
        catchError(error => {
          console.error('An error occurred:', error);
          return throwError('Something went wrong.');
        })
      );
  }


  getLang() {
    return this.http.get<any>('/assets/i18n/fr.json')
      .pipe(
        catchError(error => {
          console.error('An error occurred:', error);
          return throwError('Something went wrong.');
        })
      );
  }

  async getImagesFromHome(theme: string) {
    return this.getWikiImages(theme).subscribe(test => {
      const array = test['query']['pages'];
      let images: string[] = []
      Object.entries(array).forEach((item: any, index: any) => {
        // console.log(item[1]["imageinfo"][0]['url']);
        images.push(item[1]["imageinfo"][0])
      });
      this.homeImages = images.filter((x: any) => x.url.includes('.jpg')).slice(0, 3);
    })
  }
}
