import {Component, Input} from '@angular/core';

export interface Achievement {
  logoUrl: string;
  altImage: string;
  websiteUrl: string;
  title: string
  description: string;
}

@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss']
})
export class AchievementComponent {
  @Input() achievements: Achievement[] = [];
}
