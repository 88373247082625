<div class="container" style="padding-top: 8em">
  <app-big-hero
    [height]="637"
    [isButton]="true"
    [width]="973"
    buttonLink="/"
    imageAlt="Collaboration sur un site internet"
    imagePath="/assets/images/usability.svg"
    subtitle="Commercialiser votre activité et fidélisez vos clients partout dans le monde."
    textButton="Demander de l'aide"
    title="Site vitrine & e-commerce">
  </app-big-hero>

  <app-hero
    [imgHeight]="879"
    [imgWidth]="631"
    description="Obtenez tout ce dont vous avez besoin pour faire fonctionner votre boutique en ligne, du marchandisage à la caisse, et présentez vos produits ou services sous leur meilleur jour."
    imgAlt="Construction d'un site e-commerce"
    imgPath="/assets/images/web_shopping.svg"
    title="Vendez tous vos produits en ligne">
  </app-hero>
  <app-hero
    [imgHeight]="565"
    [imgWidth]="853"
    description="Permettez à vos clients de consulter rapidement vos disponibilités et de prendre rendez-vous sur votre site web."
    imgAlt="Prise de rendez-vous en ligne"
    imgPath="/assets/images/booking.svg"
    title="Fixer des rendez-vous et vendre des cours">
  </app-hero>
  <app-hero
    [imgHeight]="630"
    [imgWidth]="809"
    description="Qu'il s'agisse de cours ou d'ateliers virtuels, de bulletins d'information, de podcasts ou de séries vidéo, les sites réservés aux utilisateurs vous permettent de créer, d'exploiter et de rentabiliser la puissance de votre communauté en ligne."
    imgAlt="Construction d'un site e-commerce"
    imgPath="/assets/images/community.svg"
    title="Créez votre communauté">
  </app-hero>
</div>

<div class="container-fluid mt-5 pt-4 pb-4">
  <div class="row mx-auto justify-content-center d-flex flex-column mb-4">
    <div class="col-sm-10 col-md-8 text-center align-self-center">
      <h2 class="fs-1">Nous utilisons les technologies que vous souhaitez</h2>
    </div>
  </div>
  <div class="row overflow-hidden">
    <ul class="d-flex animated-list1">
      <li *ngFor="let techno of technos1" class="d-flex">
        <img
          [alt]="techno.imgAlt"
          [height]="techno.imgHeight"
          [ngSrc]="techno.imgPath"
          [width]="techno.imgWidth"
          class="img-techno"
        >
      </li>
    </ul>
    <ul class="d-flex animated-list2 mt-5" style="direction: rtl">
      <li *ngFor="let techno of technos2" class="d-flex">
        <img
          [alt]="techno.imgAlt"
          [height]="techno.imgHeight"
          [ngSrc]="techno.imgPath"
          [width]="techno.imgWidth"
          class="img-techno"
        >
      </li>
    </ul>
  </div>
  <div class="row flex-column flex-column gap-5">
    <div class="col">
      <app-pancake
        [pancakeConfig]="pancakeConfigSEO"
        [pancakes]="pancakeItemsSEO">
      </app-pancake>
    </div>
  </div>
</div>

<app-hero
  [imgHeight]="764"
  [imgWidth]="776"
  description="
          Ne vous limitez pas à une seul langue ! Donnez à votre entreprise un avantage compétitif en offrant un site internet multilingue.
          Avec L'IA du Climat, élargir votre portée n'a jamais été aussi simple.
        "
  imgAlt="Construction d'un site e-commerce"
  imgPath="../assets/images/around_the_world.svg"
  title="Propulsez votre site internet à l'international">
</app-hero>

