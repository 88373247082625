<div class="row row-cols-1 row-cols-md-3 mb-3 mt-5 text-center m-0 gap-3 gap-sm-0">
  <div class="col">
    <div class="card mb-4 rounded-3 shadow-smh h-100">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">Starter</h4>
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <h1 class="card-title pricing-card-title fs-1">20€<small class="text-body-secondary fw-light">/mois<sup>*</sup></small>
        </h1>
        <p>Les bases nécessaires pour démarrer un site internet professionnelle.</p>
        <button (click)="openContactDialog()" class="w-100 btn btn-lg btn-outline-primary" type="button">Souscrire
        </button>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card mb-4 rounded-3 shadow-sm h-100">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">Standard</h4>
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <h1 class="card-title pricing-card-title fs-1">45€<small class="text-body-secondary fw-light">/mois<sup>*</sup></small>
        </h1>
        <p>Des fonctionnalités supplémentaires pour tirer le meilleur parti de votre site internet.</p>
        <button (click)="openContactDialog()" class="w-100 btn btn-lg btn-outline-primary" type="button">Souscrire
        </button>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card mb-4 rounded-3 shadow-sm border-primaryh h-100">
      <div class="card-header py-3 text-bg-primary border-primary">
        <h4 class="my-0 fw-normal">Lifetime</h4>
      </div>
      <div class="card-body d-flex flex-column justify-content-between">
        <p class="h-75 d-flex justify-content-center align-items-center">
          Payez en une seule fois et devenez pleinement propriétaire de votre site web.
        </p>
        <button (click)="openContactDialog()" class="w-100 btn btn-lg btn-primary" type="button">Nous contacter</button>
      </div>
    </div>
  </div>
</div>

<h2 class="display-6 text-center mb-4">Comparer les plans</h2>

<div class="table-responsive">
  <table class="table text-center">
    <thead>
    <tr>
      <th style="width: 34%;"></th>
      <th style="width: 22%;">Starter</th>
      <th style="width: 22%;">Standard</th>
      <th style="width: 22%;">Lifetime</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <th class="text-start" scope="row">
        Nom de domaine
        <svg
          class="bi bi-info-circle mx-1"
          fill="#6a6a6a" height="14" matTooltip="Obtenez 1 an gratuit pour un nouveau domaine. Offre valable pour les extensions suivantes : .com, .fr, .net, .org, .eu" viewBox="0 0 16 16" width="14"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </th>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">
        Pages
      </th>
      <td>
        1 - 3
      </td>
      <td>
        1 - 5
      </td>
      <td>
        -
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">Bande passante</th>
      <td>
        100 Mbit/s
      </td>
      <td>
        250 Mbit/s
      </td>
      <td>-</td>
    </tr>
    </tbody>
    <tbody>
    <tr>
      <th class="text-start" scope="row">
        Certificat SSL
        <svg
          class="bi bi-info-circle mx-1"
          fill="#6a6a6a" height="14" matTooltip="Protégez les données sensibles de votre site : identifiants, mots de passe, numéros de carte de crédit, etc." viewBox="0 0 16 16" width="14"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </th>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">
        Formulaire de contact
      </th>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">
        Analytics
        <svg
          class="bi bi-info-circle mx-1"
          fill="#6a6a6a" height="14"
          matTooltip="Visualisez concrètement l'efficacité de votre site web : trafic des utilisateurs, performance du référencement..."
          viewBox="0 0 16 16" width="14"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </th>
      <td></td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">
        Google Ads
        <svg
          class="bi bi-info-circle mx-1"
          fill="#6a6a6a" height="14" matTooltip="Implémentation des balises Google Ads" viewBox="0 0 16 16" width="14"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
        </svg>
      </th>
      <td></td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">Auto hébergé</th>
      <td></td>
      <td>
      </td>
      <td>
        <svg class="bi bi-check2" fill="currentColor" height="24" viewBox="0 0 16 16" width="24"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
      </td>
    </tr>
    <tr>
      <th class="text-start" scope="row">Support</th>
      <td>24/7</td>
      <td>24/7</td>
      <td>-</td>
    </tr>
    </tbody>
  </table>
</div>

