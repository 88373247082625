<div class="container" style="padding-top: 8em">
  <app-big-hero
    [height]="845"
    [isButton]="true"
    [width]="576"
    buttonLink="/"
    imageAlt="Représentation de plusieurs nuages"
    imagePath="/assets/images/cloud_sync.svg"
    subtitle="Des solutions cloud flexibles pour répondre à tous vos besoins."
    title="Sécurisez vos données">
  </app-big-hero>
  <div class="row justify-content-evenly mt-5 mb-5">
    <a *ngFor="let partner of partners" [href]="partner.link" class="col-3 col-md-2 col-xl-1" id="partners">
      <img [alt]="partner.alt" [ngSrc]="partner.img" class="w-100" height="200" width="200">
    </a>
  </div>
</div>
<div class="container-fluid pt-5 pb-5" id="accordion">
  <div class="row justify-content-center">
    <div class="col text-center">
      <h3 class="fs-1">Solutions Cloud</h3>
      <p>Solutions cloud sur mesure pour une entreprise performante</p>
    </div>
  </div>
  <div class="row justify-content-center justify-content-md-evenly">
    <div class="col-10 col-sm-6 col-md-4 d-flex align-items-center">
      <img alt="" class="w-100" height="508" id="imgOne" ngSrc="./assets/images/file_bundle.svg" width="785">
      <img alt="" class="w-100" height="646" id="imgTwo" ngSrc="./assets/images/file_sync.svg" width="860">
      <img alt="" class="w-100" height="462" id="imgThree" ngSrc="./assets/images/teamwork.svg" width="897">
    </div>
    <div class="col-12 col-sm-11 col-md-6 col-xl-5 align-items-center d-flex align-items-center">
      <div class="accordion accordion-flush w-100" id="accordionFlushExample">
        <div class="accordion-item bg-transparent">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              aria-controls="flush-collapseOne"
              aria-expanded="true"
              class="accordion-button bg-transparent shadow-none fs-5 fw-semibold text-black gap-3"
              data-bs-target="#flush-collapseOne"
              data-bs-toggle="collapse"
              type="button">
              <span class="bg-primary rounded-4 accordion-icon d-flex align-items-center justify-content-center">
                <svg
                  class="bi bi-file-earmark-lock-fill"
                  fill="white" height="25"
                  viewBox="0 0 16 16"
                  width="25"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 7a1 1 0 0 1 2 0v1H7V7zM6 9.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 9h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 12h-3a.637.637 0 0 1-.395-.125C6.02 11.807 6 11.742 6 11.7V9.3z"/>
                  <path
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 1 1 4 0z"/>
                </svg>
              </span>
              <span>
                Sécurisez
              </span>
            </button>
          </h2>
          <div
            aria-labelledby="flush-headingOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionFlushExample"
            id="flush-collapseOne">
            <div class="accordion-body">
              Assurez la sécurité de vos données sensibles avec notre solution de stockage cloud hautement sécurisée.
              Nous vous offrons une protection optimale contre les cybermenaces et les attaques informatiques.
            </div>
          </div>
        </div>
        <div class="accordion-item bg-transparent">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button
              aria-controls="flush-collapseTwo"
              aria-expanded="false"
              class="accordion-button collapsed bg-transparent shadow-none fs-5 fw-semibold text-black gap-3"
              data-bs-target="#flush-collapseTwo"
              data-bs-toggle="collapse"
              type="button">
              <span
                class="bg-primary rounded-4 accordion-icon d-flex align-items-center justify-content-center">
                <svg
                  class="bi bi-arrow-repeat"
                  fill="white"
                  height="25"
                  viewBox="0 0 16 16"
                  width="25"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                  <path
                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                    fill-rule="evenodd"/>
                </svg>
              </span>
              <span>
                Synchronisez
              </span>
            </button>
          </h2>
          <div
            aria-labelledby="flush-headingTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
            id="flush-collapseTwo">
            <div class="accordion-body">
              Simplifiez la gestion de vos données grâce à notre solution de synchronisation cloud.
              Vous pouvez accéder à vos fichiers à partir de n'importe quel appareil et les synchroniser automatiquement
              en temps réel.
            </div>
          </div>
        </div>
        <div class="accordion-item bg-transparent">
          <h2 class="accordion-header" id="flush-headingThree">
            <button
              aria-controls="flush-collapseThree"
              aria-expanded="false"
              class="accordion-button collapsed bg-transparent shadow-none fs-5 fw-semibold text-black gap-3"
              data-bs-target="#flush-collapseThree"
              data-bs-toggle="collapse"
              type="button">
              <span
                class="bg-primary rounded-4 accordion-icon d-flex align-items-center justify-content-center">
                <svg
                  class="bi bi-share-fill"
                  fill="white"
                  height="25"
                  viewBox="0 0 16 16"
                  width="25"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                </svg>
              </span>
              <span>
                Collaborez
              </span>
            </button>
          </h2>
          <div
            aria-labelledby="flush-headingThree"
            class="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
            id="flush-collapseThree">
            <div class="accordion-body">
              Stimulez la collaboration et la créativité au sein de votre entreprise avec notre solution de travail
              collaboratif en cloud.
              Vous pouvez facilement partager vos fichiers et vos projets avec vos collègues et collaborer en temps
              réel.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col text-center">
      <h3 class="fs-1">Pourquoi utiliser le Cloud ?</h3>
    </div>
  </div>
  <div class="row justify-content-around mt-3">
    <section
      *ngFor="let solution of solutions; let i = index"
      class="col-11 col-sm-8 col-md-6 col-xl-4 gap-3 p-4 shadow-sm rounded-3 d-flex flex-column">
      <div [ngSwitch]="i" class="col">
        <svg
          *ngSwitchCase="0"
          class="bi bi-person-workspace"
          height="50"
          style="fill: var(--bs-primary)"
          viewBox="0 0 16 16"
          width="50"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
          <path
            d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
        </svg>
        <svg
          *ngSwitchCase="1"
          class="bi bi-download"
          height="50"
          style="fill: var(--bs-primary)"
          viewBox="0 0 16 16"
          width="50"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path
            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
        <svg
          *ngSwitchCase="2"
          class="bi bi-file-text"
          height="50"
          style="fill: var(--bs-primary)"
          viewBox="0 0 16 16"
          width="50"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
          <path
            d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
        </svg>
      </div>
      <div class="col">
        <h3 class="fs-3 fw-semibold"> {{ solution.title }} </h3>
      </div>
      <div class="col-12">
        <p class="text-gray"> {{ solution.description}} </p>
      </div>
    </section>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col text-center">
      <h3 class="fs-1">Installer Nextcloud dès maintenant !</h3>
    </div>
  </div>
  <div class="row justify-content-evenly mt-3">
    <div class="col-5 col-xl-2">
      <a href="https://nextcloud.com">
        <img
          alt="Nextcloud Logo"
          class="w-100 h-auto"
          height="200"
          ngSrc="assets/images/nextcloud_logo.webp"
          width="200">
      </a>
    </div>
    <div class="col-11 col-xl-5 p-4">
      <p class="fs-5 lh-base">
        Nextcloud est une formidable soulution open-source de collaboration et de partage de fichier.
        Apprenez dans ce tutoriel comment l'installer et le configurer.
      </p>
      <a
        class="p-4 text-decoration-none"
        color="primary"
        href="https://doc.bugprog.studio/docs/intro/"
        mat-flat-button>
        Consulter le tutoriel
      </a>
    </div>
  </div>
</div>
