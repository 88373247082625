<mat-tab-group
  (selectedIndexChange)="onChangeTab($event)"
  [selectedIndex]="selected"
  mat-align-tabs="center"
  mat-stretch-tabs="false">
  <mat-tab label="Site internet">
    <app-pricing-website></app-pricing-website>
  </mat-tab>
  <mat-tab label="business & e-commerce">
    <app-e-pricing-commerce></app-e-pricing-commerce>
  </mat-tab>
</mat-tab-group>
<div class="row mt-3 d-flex justify-content-center">
  <div class="col-11 col-md-12 bg-light p-4 p-md-5 rounded-4">
    <h3 class="fs-5">Plans business et e-commerce</h3>
    <p>Développez et gérez votre activité en ligne avec une vitrine personnalisée, la gestion des catalogues et des
      commandes, l'expédition et les taxes, des canaux de vente intégrés et bien plus encore.</p>
    <button (click)="onChangeTab(1)" class="btn text-primary">
      En savoir plus
      <svg class="bi bi-arrow-right" fill="currentColor" height="16" viewBox="0 0 16 16" width="16"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
          fill-rule="evenodd"/>
      </svg>
    </button>
  </div>
</div>
<p><small>* Inclus les frais d'hébergement mensuels. Frais de mise en service: 400,00€</small></p>
