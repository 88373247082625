<div >
  <button (click)="toggleAssistantModal()" color="primary" mat-fab style="position: fixed; right: 20px; bottom: 20px;">
    <svg
      class="bi bi-chat-dots"
      fill="white"
      height="28"
      viewBox="0 0 16 16"
      width="28"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
      <path
        d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
    </svg>
  </button>
  <div *ngIf="assistantShowModal" class="assistant-modal">
    <div class="assistant-header d-flex mt-2 justify-content-between">
      <div class="d-flex align-items-center">
        <div class="leave-arrow" (click)="toggleAssistantModal()">
          <svg width="800px" height="800px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" fill="#6A6E71"/>
          </svg>
        </div>
        <div class="font-medium-1 fw-medium ms-2">Assistant</div>
      </div>
      <div class="me-3">
        <img src="assets/images/chatbot.png" alt="profile picture" class="rounded-circle" width="55" height="55">
      </div>
    </div>
    <div class="mt-2 messages">
      <div *ngFor="let message of messages" class="mb-2">
        <div
          *ngIf="message.from === 'bot'"
          class="d-flex justify-content-start ms-2"
        >
          <img ngSrc="assets/images/chatbot.png" alt="profile picture" class="rounded-circle" width="55" height="55">
          <div class="message-bg rounded ms-2 p-2" style="max-width: 250px;">
              {{ message.message }}
          </div>
        </div>
        <div
          *ngIf="message.from === 'user'"
          class="d-flex mt-2 me-2 flex-row-reverse"
        >
          <img ngSrc="assets/images/user.png" alt="profile picture" class="mt-1 rounded-circle" width="45" height="45">
          <div class="message-bg-user rounded me-2 p-2" style="max-width: 250px;">
              {{ message.message }}
          </div>
        </div>
      </div>
    </div>
    <div class="input-user position-relative">
      <div *ngIf="isGptLoading" class="robot-writing">
        <img src="assets/images/chatbot.png" alt="profile picture" class="rounded-circle" width="55" height="55">
        <div class="typing">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <textarea placeholder="Écrivez votre message" (keydown.enter)="sendMessage()"
        [(ngModel)]="userMessage"
      ></textarea>
      <button class="button-input bg-primary" (click)="sendMessage()">
        Envoyer
      </button>
    </div>
  </div>
</div>
