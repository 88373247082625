import {Meta, Title} from '@angular/platform-browser';
import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

export interface TagConfig {
  title: string,
  description: string
}

@Injectable({providedIn: 'root'})
export class SeoService {

  link: HTMLLinkElement = this.document.createElement('link');
  baseUrl = 'https://bugprog.studio'

  constructor(
    private readonly metaTagService: Meta,
    private readonly titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  /**
   * Generate meta tag dynamically
   * @param config
   */
  generateTags(config: TagConfig) {
    this.titleService.setTitle('L\'IA du Climat - ' + config.title);
    this.metaTagService.updateTag({name: 'description', content: config.description});
  }

  /**
   * Create canonical link
   * @param url
   */
  createCanonicalLink(url?: string) {
    let canURL = url == undefined ? this.document.URL : url;
    this.link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(this.link);
    this.link.setAttribute('href', this.baseUrl + canURL);
  }

  /**
   * Update canonical link dynamically
   * @param canURL
   */
  updateCanonicalLink(canURL: string) {
    this.link.setAttribute('href', this.baseUrl + canURL);
    this.document.getElementsByTagName('base')[0].href = canURL;
  }
}
