<footer class="container-fluid">
  <div class="row bg-primary pb-5">
    <div class="col-11 col-sm-10 col-xl-7 mx-auto bg-white shadow rounded-3" id="contactBar">
      <div class="row mx-auto h-100">
        <div class="col-sm-12 col-md-10 mx-auto d-block text-center">
          <h4 class="mb-5"></h4>
<!--          <button (click)="openContactDialog()" class="p-4" color="primary" mat-flat-button>Faire une demande</button>-->
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-9 mx-auto d-flex justify-content-between flex-wrap">
      <section *ngFor="let section of sections" class="col-12 col-md-4 col-xl-3 mx-auto d-block">
        <h5 class="fs-2 font-weight-bold">{{section.sectionName}}</h5>
        <ul class="fs-6 lh-lg p-0 m-3 list-unstyled">
          <li *ngFor="let item of section.links">
            <a *ngIf="item.hrefAttribute" [href]="item.hrefAttribute"
               class="text-decoration-none text-black">{{item.text}}</a>
            <a *ngIf="item.routerAttribute" [routerLink]="item.routerAttribute"
               class="text-decoration-none text-black">{{item.text}}</a>
            <span (click)="openContactDialog()" *ngIf="item.openContactDialog" class="text-decoration-none text-black"
                  style="cursor: pointer">{{item.text}}</span>
          </li>
        </ul>
      </section>
    </div>
  </div>
  <hr class="mx-auto d-block" style="width: 60%; opacity: 0.1;">
  <div class="row text-center">
    <p style="font-size: 0.8em; opacity: 0.4">
      © Copyright 2023 L'IA du Climat
    </p>
  </div>
</footer>
