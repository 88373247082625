import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SeoService} from '../../services/seo.service';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})
export class CloudComponent implements OnInit, AfterViewInit {
  partners: { img: string, alt: string, link: string }[] = [
    {
      img: './assets/images/nextcloud_logo.webp',
      alt: 'Logo Nextcloud',
      link: 'https://nextcloud.com/'
    },
    {
      img: './assets/images/truenas_logo.webp',
      alt: 'Logo Truenas',
      link: 'https://www.truenas.com/'
    },
    {
      img: './assets/images/digital.webp',
      alt: 'Logo Digital Ocean',
      link: 'https://www.digitalocean.com/'
    },
    {
      img: './assets/images/ovh.webp',
      alt: 'Logo OVH',
      link: 'https://www.ovhcloud.com/fr/'
    },
  ]

  solutions: { title: string, description: string }[] = [
    {
      title: 'Télétravail',
      description: 'La crise du COVID-19 a créé un besoin soudain pour les petite/grande ' +
        'entreprises de commencer le travail à domicile.'
    },
    {
      title: 'Déploiement en masse',
      description: 'Ajoutez facilement vos actifs informatiques et sécurisez-les à l\'aide d\'une ' +
        'interface intuitive et centralisée.'
    },
    {
      title: '\'0\' Papier',
      description: 'On ne peut ignorer les avantages potentiels de la collaboration en ligne en termes d\'efficacité ' +
        'et de productivité.'
    }
  ]

  constructor(private seoService: SeoService) {
  }

  ngOnInit() {
    this.seoService.generateTags({
      title: 'Cloud',
      description: 'Découvrez notre expertise en matière de solutions cloud pour les entreprises. Nous créons des solutions cloud sécurisées, évolutives et personnalisées pour répondre aux besoins spécifiques de nos clients. Contactez-nous pour en savoir plus.'
    });
  }

  ngAfterViewInit() {

    const collapseOne = document.getElementById('flush-collapseOne')!;
    collapseOne.addEventListener('shown.bs.collapse', () => {
      this.hideImages();
      document.getElementById('imgOne')!.style.display = 'block';
    });

    const collapseTwo = document.getElementById('flush-collapseTwo')!;
    collapseTwo.addEventListener('shown.bs.collapse', () => {
      this.hideImages();
      document.getElementById('imgTwo')!.style.display = 'block';
    });

    const collapseThree = document.getElementById('flush-collapseThree')!;
    collapseThree.addEventListener('shown.bs.collapse', () => {
      this.hideImages();
      document.getElementById('imgThree')!.style.display = 'block';
    });
  }

  hideImages() {
    document.getElementById('imgOne')!.style.display = 'none';
    document.getElementById('imgTwo')!.style.display = 'none';
    document.getElementById('imgThree')!.style.display = 'none';
  }
}
