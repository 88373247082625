import {Injectable} from "@angular/core";
import {Api} from "../helpers/api";

@Injectable({providedIn: "root"})
export class TranslationService {

  getTranslation(fileSource: string, lang: string) {
    console.log(      JSON.stringify({
      json: fileSource,
      lang
    }))
    return Api.functions().createExecution(
      '657291a8eb28d6ba58d9',
      JSON.stringify({
        json: fileSource,
        lang
      })
    )
  }
}
