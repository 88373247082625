import {WorkflowData, WorkflowItem} from '../shared/components/workflow/workflow.component';
import {Achievement} from '../shared/components/achievement/achievement.component';
import {FaqItems} from '../shared/components/faq/faq.component';
import {Component, OnInit} from '@angular/core';
import {ServiceItem} from './service-list/service-list.component';
import {Pancake, PancakeConfig} from '../shared/components/pancake/pancake.component';
import {SeoService} from '../../services/seo.service';

interface Integrations {
  imgPth: string;
  imgAlt: string;
  legend: string;
}

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

  workflowItems: WorkflowItem[] = [
    {
      number: '1.',
      title: 'Adopter votre design',
      description: 'Nous élaborons ensemble le design de votre application mobile.',
      imagePath: './assets/images/details.svg',
      imageWidth: 860,
      imageHeight: 609,
      altImage: 'Proposition de design'
    },
    {
      number: '2.',
      title: 'Ajoutez votre contenu',
      description: 'Ajouter vos propres contenus (images, vidéo…) pour personnaliser votre App.',
      imagePath: './assets/images/app_installation.svg',
      imageWidth: 738,
      imageHeight: 729,
      altImage: 'Ajout de contenue'
    },
    {
      number: '3.',
      title: 'Publiez votre app',
      description: 'Votre App est disponible sur Google Play et/ou Apple Store.',
      imagePath: './assets/images/develop_app.svg',
      imageWidth: 728,
      imageHeight: 680,
      altImage: 'Publication d\'un poste'
    }
  ];

  workflowData: WorkflowData = {
    title: 'Donnez vie à vos idées avec des experts du secteur',
    subtitle: 'C\'est aussi simple que 1, 2, 3...'
  }

  achievements: Achievement[] = [
    {
      logoUrl: 'https://gitlab.com/m9712/mygarden/-/raw/master/src/assets/images/MyGarden.png',
      altImage: 'MyGarden logo',
      websiteUrl: 'https://play.google.com/store/apps/details?id=fr.bugprogEnterprise.mygarden',
      title: 'MyGarden',
      description: 'Une application pour gérer votre potager tout en respectant l\'environnement.'
    }
  ]

  services: ServiceItem[] = [
    {
      title: 'Une application mobile entièrement personnalisée',
      subtitle: 'Développement d\'application à partir de zéro pour donner vie à des idées innovantes.',
      imgPath: '../../assets/images/mobile_apps.svg',
      imgAlt: 'Développement d\'une application mobile',
      imgWidth: 831,
      imgHeight: 730
    },
    {
      title: 'Applications de gestion des flux de travail',
      subtitle: 'Automatisez les processus administratifs et rendez votre équipe plus efficace.',
      imgPath: '../../assets/images/process.svg',
      imgAlt: 'Workflow',
      imgWidth: 855,
      imgHeight: 611
    },
    {
      title: 'Applications d\'événements',
      subtitle: 'Distinguez-vous et faites plaisir à vos participants en leur proposant une application mobile pour votre événement.',
      imgPath: '../../assets/images/amusement_park.svg',
      imgAlt: 'Amusement dans un parc',
      imgWidth: 712,
      imgHeight: 380
    },
    {
      title: 'Applications Ecommerce',
      subtitle: 'Augmentez le revenu par client et l\'engagement des clients pour votre boutique Shopify.',
      imgPath: '../../assets/images/marketing.svg',
      imgAlt: 'Marketing',
      imgWidth: 831,
      imgHeight: 730
    },
    {
      title: 'Applications pédagogiques',
      subtitle: 'Améliorez la communication et l\'engagement avec vos étudiants et vos clients.',
      imgPath: '../../assets/images/education.svg',
      imgAlt: 'Education avec les livres',
      imgWidth: 744,
      imgHeight: 539
    },
    {
      title: 'Applications de création de contenu',
      subtitle: 'Distribuez du contenu, faites participer votre public et créez votre communauté.',
      imgPath: '../../assets/images/designer.svg',
      imgAlt: 'Créateur de contenu',
      imgWidth: 804,
      imgHeight: 782
    }
  ]

  faqItems: FaqItems[] = [
    {
      question: 'Puis-je connecter mon application à mon site internet ?',
      answer: 'Bien sûr, vous pouvez connecter les données générées sur votre site web sur votre application mobile. ' +
        'Il peut s’agir d’une base de donnée utilisateurs, des préférences, des articles, blogs...',
    },
    {
      question: 'Comment publier mon application sur Apple Store / Google Play ?',
      answer: 'Pour publier des applications IOS/Android, vous devez créer un compte Apple/Google Developer. ' +
        'Lors de l\'inscription, vous devrez payer des frais. ' +
        'Après l\'inscription, attendez l\'approbation de Google/Apple. Votre application mobile doit être optimisée pour le ' +
        'magasin d\'applications avant d\'être chargée sur Google Play/Apple Store. Vous devez soumettre le fichier AAB/IPA et les ' +
        'captures d\'écran sur Play Store/Apple Store pour publier l\'application. BugPrug Studio vous aidera à publier votre application sur Google Play/Apple Store.'
    },
    {
      question: 'Comment gagner de l\'argent avec une application mobile ?',
      answer: 'Vous avez donc une application gratuite et vous vous demandez maintenant comment gagner de l\'argent avec une application totalement gratuite ? ' +
        'Il existe différentes façons de gagner de l\'argent avec votre application : Publicité, Achats In-App, Modèle d\'abonnement, Parrainage...'
    },
    {
      question: 'Combien coûte la création d\'une application mobile ?',
      answer: 'Il n\'existe pas de réponse unique. Cela dépend certainement de la taille et de la portée de l\'application que vous voulez créer.'
    },
    {
      question: 'Puis-je moi-même modifier le contenu de mon application ?',
      answer: 'Réponse courte: non. Nous construisions votre application de zéro et n’utilisons aucun CMS. ' +
        'Cela nous permet de construire librement des applications qui répondent à vos besoins quelles que soient vos contraintes, ' +
        'mais malheureusement le seul moyen de modifier le contenu de votre application mobile est d’éditer le code.'
    }
  ]

  integrations: Integrations[] = [
    {
      imgPth: './../assets/icons/api.svg',
      imgAlt: 'API logo',
      legend: 'API de tiers'
    },
    {
      imgPth: './../assets/icons/server.svg',
      imgAlt: 'Server logo',
      legend: 'Base de donnée'
    },
    {
      imgPth: './../assets/icons/folder.svg',
      imgAlt: 'Fichier logo',
      legend: 'Stockage'
    },
    {
      imgPth: './../assets/icons/map.svg',
      imgAlt: 'Map logo',
      legend: 'Localisation'
    },
    {
      imgPth: './../assets/icons/group.svg',
      imgAlt: 'Logo d\'utilisateurs',
      legend: 'Auth & Utilisateurs'
    }
  ];

  storePancake: Pancake[] = [
    {
      title: 'Disponible partout',
      description: 'Publiez votre application sur le web, sur le Google Play Store et sur l\'Apple Store. ' +
        'Votre application Android ou votre application IOS sera parfaite sur tous les appareils, qu\'elle soit visualisée sur un smartphone, une tablette ou un ordinateur de bureau️.'
    },
    {
      title: 'Monitorez votre Application',
      description: 'Publiez votre App et découvrez des fonctionnalités qui vous permettront d\'atteindre plus de 2,5 milliards d\'utilisateurs dans le monde, de tester et d\'améliorer la qualité de vos applications, d\'augmenter votre chiffre d\'affaires, et bien plus encore.',
      buttonText: 'Nous contacter',
    }
  ];

  storeConfig: PancakeConfig = {
    imagePath: '../assets/images/apps-stores.webp',
    imgAlt: 'Logo de Apple sotre & Google play',
    isLottie: false
  }

  constructor(private seoService: SeoService) {
  }

  ngOnInit() {
    this.seoService.generateTags({
      title: 'Application mobile',
      description: 'Besoin d\'une application mobile sur mesure pour votre entreprise ? Nous sommes là pour vous aider. Découvrez notre expertise en matière de développement d\'applications mobiles et contactez-nous pour discuter de votre projet.'
    });
  }
}
