import {Component, Input} from '@angular/core';

export interface FaqItems {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  @Input('faqItems') items: FaqItems[] = [];
}
