import {Component, Input} from '@angular/core';

export interface WorkflowItem {
  number: string;
  title: string
  description: string;
  imagePath: string;
  imageWidth: number;
  imageHeight: number;
  altImage: string;
}

export interface WorkflowData {
  title: string;
  subtitle: string;
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent {
  @Input() items: WorkflowItem[] = [];
  @Input() metadata!: WorkflowData;
}
