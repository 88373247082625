<div class="container" style="padding-top: 8em">
  <div class="row h-100 ">
    <div class="col d-flex flex-wrap justify-content-evenly">
      <div class="col-12 col-xl-6 align-self-center">
        <h3 class="font-lato fw-bold fs-1 lh-base">La société</h3>
        <p class="p-2 p-lg-0 text-gray lh-base" style="text-align: justify">
          Bienvenue chez BugProg-Enterprise ! Nous sommes une entreprise spécialisée dans le développement de sites
          internet et d'applications mobiles, basée en France et intervenant dans tout le pays. Fondée en 2021, notre
          société met son expertise et son expérience à votre service pour vous aider à atteindre vos objectifs en
          ligne.
          <br>
          Nous croyons que chaque entreprise mérite une présence en ligne forte et pertinente pour atteindre ses
          objectifs de croissance et de succès.
          <br>
          Notre approche collaborative implique une étroite collaboration avec nos clients tout au long du processus de
          développement pour garantir que nous répondons à leurs besoins spécifiques. Nous sommes fiers de la qualité de
          notre travail et nous nous engageons à fournir des résultats exceptionnels à nos clients.
        </p>
      </div>
      <div class="col-xl-6 col-8 d-flex">
        <img alt="Logo de L'IA du Climat avec bordures"
             class="mx-auto d-block shadow-lg rounded-circle align-self-center"
             src="assets/icons/icon-512x512.png"
             style="max-height: 30vh; max-width: 90%;">
      </div>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="row">
    <h3 class="font-lato fw-bold fs-1 lh-base">Nos Principes</h3>
    <h6>Nos principes rejoignent à 100% les principes énoncés dans le manifeste de Mozilla.</h6>
    <div class="col-12 flex-wrap d-flex">
      <section *ngFor="let value of values" class="col-sm-12 col-md-6 col-xl-4 mt-3 p-3">
        <h2 class="fs-3 fw-bold">{{ value.title }}</h2>
        <p class="fs-6 lh-base" style="text-align: justify; color: #2D4665">{{ value.text }}</p>
      </section>
    </div>
  </div>
</div>
<div class="container mt-5">
  <div class="row">
    <h3 class="font-lato fw-bold fs-1 lh-base">Les projets que nous supportons</h3>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-evenly flex-wrap">
      <a *ngFor="let supporter of supporters" [href]="supporter.urlLink" class="align-self-center">
        <img
          [alt]="supporter.alt"
          [height]="supporter.height"
          [ngSrc]="supporter.imgLink"
          [width]="supporter.width"
          class="img-supporters">
      </a>
    </div>
  </div>
</div>
