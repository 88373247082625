import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {MoreInformationComponent} from './website/more-information/more-information.component';
import {AddReviewComponent} from './home/add-review/add-review.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {WebsiteComponent} from './website/website.component';
import {MobileComponent} from './mobile/mobile.component';
import {CloudComponent} from './cloud/cloud.component';
import {LegalComponent} from './legal/legal.component';
import {HomeComponent} from './home/home.component';
import {UserComponent} from './user.component';
import {PricingComponent} from './pricing/pricing.component';

const routes: Routes = [
  {
    path: '', component: UserComponent, children: [
      {path: '', pathMatch: 'full', component: HomeComponent},
      {
        path: 'nucleaire', children: [
          {path: '', component: WebsiteComponent},
          {path: 'site-vitrine-et-e-commerce', component: MoreInformationComponent}
        ]
      },
      {path: 'transports', component: MobileComponent},
      {path: 'ajouter-un-avis', component: AddReviewComponent},
      {path: 'qui-sommes-nous', component: AboutUsComponent},
      {path: 'mentions-legales', component: LegalComponent},
      {path: 'sitographie', component: CloudComponent},
      {path: 'prix', component: PricingComponent}
    ]
  },
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule {
}
