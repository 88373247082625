import {NavigationEnd, Router} from '@angular/router';
import {SeoService} from './services/seo.service';
import {Component, OnInit} from '@angular/core';
import {filter} from 'rxjs/operators';
import {LanguageService} from "./services/language.service";
import {MatDialog} from "@angular/material/dialog";
import {WelcomeNightComponent} from "./user/welcome-night/welcome-night.component";
import {WikimediaService} from "./services/wikimedia.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private seoService: SeoService,
    private router: Router,
    private languageService: LanguageService,
    private wikiService: WikimediaService,
    private dialog: MatDialog) {
  }

  ngOnInit() {

    this.wikiService.getWikiImages('ocean').subscribe(test => {
      const array = test['query']['pages'];
      let images: string[] = []
      Object.entries(array).forEach((item: any, index: any) => {
        // console.log(item[1]["imageinfo"][0]['url']);
        images.push(item[1]["imageinfo"][0]['url'])
      });
      images = images.filter((x: string) => x.includes('.jpg'));
      console.log(images)

      // USE images !!!!!!!!!!!!!! !TODO
    })

    this.languageService.setInitialAppLanguage();
    this.dialog.open(WelcomeNightComponent, {
      width: '35em',
      height: 'auto',
      disableClose: true,
    });
    this.seoService.createCanonicalLink();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((val: any) => {
      console.log(val.url)
      this.seoService.updateCanonicalLink(val.url)
    })
  }
}
