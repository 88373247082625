import {AfterViewInit, Component, OnInit} from '@angular/core';
import {register} from 'swiper/element/bundle';
import {LoadingService} from "../services/loading.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterViewInit {
  isLoading: boolean = false;

  constructor(private loadingService: LoadingService, ) {
  }

  ngOnInit() {
    this.loadingService.loading.subscribe(loading => {
      this.isLoading = loading;
    })
  }


  ngAfterViewInit() {
    register();
  }
}
