<div class="container" style="padding-top: 8em">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="row h-100 d-flex align-content-center">
        <div class="col-12">
          <h1 class="lh-sm">{{ translate.getTranslation('the')}} <span class="text-span" id="typeIt"></span><br> {{ translate.getTranslation('climatic')}}</h1>
        </div>
        <div class="col-12 mt-3 mb-4">
          <h3 class="fs-5">{{ translate.getTranslation('subtitle')}}</h3>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6">
      <ng-lottie
        [options]="lottieConfig"
        [styles]="{maxWidth: '95%'}"
        (complete)="complete()"
        (animationCreated)="animationCreated($event)"
        width="30em"
      >
      </ng-lottie>
    </div>
  </div>
  <div class="row" style="margin-top: 9em">
    <div class="row m-0 justify-content-center d-flex flex-column">
      <div *ngIf="wikimediaService.homeImages.length > 2" class="col d-flex justify-content-center text-center align-self-center flex-wrap">
        <!--        SERVICE ITEMS-->
        <section *ngFor="let service of services; let i = index"
                 class="pe-auto serviceCard p-4 col-12 col-sm-6 col-md-4 border-bottom"
                 routerLink="{{service.link}}">
          <div [ngSwitch]="i" class="mb-3">
            <img
              *ngSwitchCase="0"
              [src]="wikimediaService.homeImages[0].url"
              class="services-img"
            />
            <img
              *ngSwitchCase="1"
              [src]="wikimediaService.homeImages[1].url"
              class="services-img"
            />
            <img
              *ngSwitchCase="2"
              [src]="wikimediaService.homeImages[2].url"
              class="services-img"
            />
          </div>
          <h2 class="fs-5 fw-semibold">{{ this.translate.getTranslation(service.title) }}</h2>
          <p style="color: var(--bs-gray-500)">{{ this.translate.getTranslation(service.description) }}</p>
        </section>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bg-primary pt-5" style="margin-top: 8em;">
  <div class="row d-flex flex-column py-5" style="min-height: 520px;">
    <div class="col-sm-10 col-md-8 al text-center align-self-center">
      <h3 class="fs-1 mb-5 text-white">{{ this.translate.getTranslation('quiz') }}</h3>

    </div>
    <div class="row justify-content-center ">
      <div class="col-5">
        <swiper-container
          #swiper>
          <swiper-slide *ngFor="let question of questions" class="">
            <div
              [id]="question.random"
              class="col-8 random col-md-5 col-xl-8 rounded-3 text-center bg-light p-4 w-100 h-100">
<!--              <img-->
<!--                [alt]="'Avatar - ' + review.name"-->
<!--                class="rounded-circle mx-auto"-->
<!--                height="50"-->
<!--                src="{{ review.imagePath }}"-->
<!--                style="width: 70px; height: auto;" width="50">-->
              <p class=" my-3" style="font-size: 1.8em">“{{ question.prompt }}”</p>
              <div class="row row-cols-2">
                <div class="col">
                  <button (click)="checkRes(question, false, question.random)" class="btn btn-res btn-no">INTOX</button>
                </div>
                <div class="col">
                  <button (click)="checkRes(question, true, question.random)" class="btn btn-res btn-yes">INFO</button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  </div>
</div>
<app-pancake
  [pancakeConfig]="pancakeConfig"
  [pancakes]="pancakeItems">
</app-pancake>
