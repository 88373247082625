<nav class="navbar navbar-expand-xl z-2 d-flex bg-white fixed-top" id="mainNav">
  <div class="container d-flex m-auto">
    <div class="justify-content-around" style="flex: 3 0 auto">
      <img alt="L'IA du Climat Banner"
           height="100"
           routerLink="/"
           src="assets/images/BugProg_Studio.webp"
           style="cursor: pointer; width: 300px; height: auto; max-width: 70vw"
           width="400">
    </div>
    <button aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler border-0 shadow-none"
            data-bs-target="#navbarToggler" data-bs-toggle="collapse" type="button">
      <svg class="bi bi-list"
           fill="currentColor"
           height="30"
           viewBox="0 0 16 16"
           width="30"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          fill-rule="evenodd"/>
      </svg>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="d-flex navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-between">
        <li>
          <a [routerLinkActiveOptions]="{exact: true}" aria-current="page" class="nav-link fs-6 text-black"
             routerLink="/" routerLinkActive="active">Accueil</a>
        </li>
        <li>
          <a aria-current="page" class="nav-link fs-6 text-black" routerLink="/nucleaire"
             routerLinkActive="active">Le nucléaire
          </a>
        </li>
<!--        <li>-->
<!--          <a aria-current="page" class="nav-link fs-6 text-black" routerLink="/transports"-->
<!--             routerLinkActive="active">Les transports</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a aria-current="page" class="nav-link fs-6 text-black" routerLink="/sitographie"-->
<!--             routerLinkActive="active">Sitographie</a>-->
<!--        </li>-->
        <li>
          <button
            (click)="openDialog()"
            color="primary"
            mat-flat-button
          >
            Paramètres AI
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
