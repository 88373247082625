<div>
  <app-snack></app-snack>
  <app-header></app-header>
  <app-contact></app-contact>
  <app-assistant ></app-assistant>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<div *ngIf="isLoading">
<app-loading></app-loading>
</div>

