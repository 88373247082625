import {Component, Input} from '@angular/core';

export interface ServiceItem {
  title: string;
  subtitle: string;
  imgPath: string;
  imgAlt: string;
  imgWidth: number;
  imgHeight: number;
}

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent {
  @Input() services!: ServiceItem[];
}
