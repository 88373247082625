import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {Api} from '../helpers/api';
import {Models} from 'appwrite';

export interface ContactForm {
  subject: string;
  message: string;
  name: string;
  firstname: string;
  phone_number: string;
  email: string;
}

export interface FormReview {
  companyName: string;
  website: string;
  name: string;
  firstname: string;
  email: string;
  role: string;
  textReview: string;
}

export interface Review {
  companyName: string;
  website: string;
  name: string;
  firstname: string;
  email: string;
  role: string;
  textReview: string;
  imagePath: string;
}

@Injectable({providedIn: 'root'})
export class FormsService {

  sendContactForm(form: ContactForm) {
    return Api.functions().createExecution(
      environment.contactFunctionId,
      JSON.stringify(form)
    );
  }

  fetchContactsForms(): Promise<Models.DocumentList<any>> {
    return Api.databases().listDocuments(environment.formsDatabaseId, environment.contactCollectionId)
  }

  addReview(form: FormReview, image: File) {
    return from(Api.storages().createFile(
      environment.avatarBucketId,
      'unique()',
      image
    ).then((res: Models.File): Promise<Models.Document> | null => {
      if (res.$id) {
        return Api.databases().createDocument(
          environment.formsDatabaseId,
          environment.reviewCollectionId,
          res.$id,
          form
        )
      }
      return null;
    }))
  }

  fetchReviews() {
    return Api.databases().listDocuments(environment.formsDatabaseId, environment.reviewCollectionId).then((reviews) => {
      return reviews.documents.map((review) => {
        Api.storages().getFileView(environment.avatarBucketId, review.$id);
        const url: URL = Api.storages().getFilePreview(
          environment.avatarBucketId,
          review.$id,
          70,
          70,
          'center',
          80);
        return {
          ...review,
          ...{imagePath: url.href}
        }
      })
    });
  }

  listAllStorageFiles(): Observable<object> {
    return from([]);
  }
}
