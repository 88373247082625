<div class="container">
  <div class="row justify-content-center gap-5">
    <div class="col-sm-10 col-md-8 al text-center align-self-center">
      <h2 class="fs-1">F.A.Q</h2>
    </div>
    <div class="col-12 col-md-8">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of items" class="p-3">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ item.question }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="lh-base"> {{ item.answer }} </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
