import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ContactModalComponent} from '../shared/components/contact/contact-modal/contact-modal.component';
import {Pancake, PancakeConfig} from '../shared/components/pancake/pancake.component';
import {FormsService, Review} from '../../services/forms.service';
import {SeoService} from '../../services/seo.service';
import {MatDialog} from '@angular/material/dialog';
import {SwiperOptions} from 'swiper/types';
import TypeIt from "typeit";
import {TranslateService} from "@ngx-translate/core";
import {WikimediaService} from "../../services/wikimedia.service";
import {LanguageService} from "../../services/language.service";
import {getLineAndCharacterFromPosition} from "@angular/cdk/schematics/update-tool/utils/line-mappings";

interface Services {
  title: string;
  description: string;
  link: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('swiper', {static: true}) swiperRef: ElementRef | undefined;

  reponse: string[] = [
    'Le charbon est la source d’énergie la plus polluante au monde.',
    'L’acronyme GIEC signifie-t-il « Grandes interrogations sur les émissions de carbone » ?',
    'L’énergie nucléaire est une énergie renouvelable.',
    'Le changement climatique en France ne se produira pas',
    'Les objectifs de développement durable ne sont pas nécessaires pour parvenir à un avenir meilleur pour tous',
    'Les mesures mises en place par les décideurs suffisent à atténuer les conséquences du réchauffement climatique',
    'Les océans produisent-ils plus d’oxygène que les forêts ?',
    'Le changement climatique est la principale cause de l’effondrement de la biodiversité.',
    'L’impact du changement climatique est-il plus important sur les pôles ?',
    'La première conférence mondiale sur le climat eu lieu en 1979.'
  ]
  lang: object = {};

  questions: { prompt: string, response: boolean, random: string }[] = [
    {
      prompt: this.reponse[0],
      response: true,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[1],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[2],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[3],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[4],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[5],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[6],
      response: true,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[7],
      response: false,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[8],
      response: true,
      random: this.getRandom()
    },
    {
      prompt:this.reponse[9],
      response: true,
      random: this.getRandom()
    }
  ]


  services: Services[];
  pancakeItems: Pancake[] = [
    {
      title: 'solutions',
      description: 'solutions1',
    },
    {
      title: 'shift',
      description:
        'shift1',
      isUrl: true,
      buttonText: 'En savoir plus',
      url: 'https://theshiftproject.org/'
    }
  ]
  pancakeConfig: PancakeConfig = {
    imagePath: '/assets/lottie/work.json',
    imgAlt: 'work',
    isLottie: true
  }
  reviews: Review[] = [];
  public lottieConfig: Object;
  private anim: any;

  constructor(
    private dialog: MatDialog,
    private formService: FormsService,
    private seoService: SeoService,
    public wikimediaService: WikimediaService,
    public translate: LanguageService
  )
  {
    this.lottieConfig = {
      path: '/assets/lottie/rocket-purple.json',
      loop: false,

    }
  }

  ngOnInit() {
    this.translate.language.subscribe(lang => {
      // Object.keys(lang).forEach(line => {
      //   console.log(line)
      // })
      this.lang = lang;
      // console.log('hi !', lang)
    })
    this.services = [
      {
        title: 'firstitem',
        description: 'firstitem1',
        link: '/nucleaire'
      },
      {
        title: 'seconditem',
        description: 'seconditem2',
        link: '/transports'
      },
      {
        title: 'thirditem',
        description: 'thirditem2',
        link: '/sitographie'
      },
    ]
    // this.formService.fetchReviews().then((reviews) => {
    //   reviews.forEach((review: any) => {
    //     this.reviews.push({
    //       name: review['name'],
    //       firstname: review['firstname'],
    //       website: review['website'],
    //       role: review['role'],
    //       textReview: review['textReview'],
    //       email: review['email'],
    //       companyName: review['companyName'],
    //       imagePath: review['imagePath']
    //     })
    //   })
    // });

    this.seoService.generateTags({
      title: 'Accueil',
      description: 'Boostez votre entreprise avec nos solutions digitales sur mesure. Nous créons des sites web, des applications mobiles et des solutions cloud pour vous aider à atteindre vos objectifs commerciaux. Contactez-nous dès aujourd\'hui pour en savoir plus.'
    });
  }

  ngAfterViewInit() {
    new (TypeIt as any)("#typeIt", {
      speed: 100,
      loop: true
    })
      .type(this.translate.getTranslation('warming'), {delay: 2000})
      .delete(() => {
      }, {delay: 1000, instant: false})
      .type(this.translate.getTranslation('changement'), {delay: 2000})
      .delete(() => {
      }, {delay: 1000})
      .type(this.translate.getTranslation('disruption'), {delay: 2000})
      .delete(() => {
      }, {delay: 1000})
      .go();

    const options: SwiperOptions = {
      effect: 'cards',
      centeredSlides: true,
      autoHeight: true
    }
    Object.assign(this.swiperRef?.nativeElement, options);
  }

  checkRes(question: { prompt: string, response: boolean, random: string }, res: boolean, random: string) {
    if (res !== question.response) {
      document.getElementById(random)!.classList.add("blink");
      setTimeout(() => {
        document.getElementById(random)!.classList.remove("blink");
      }, 2000)
    } else {
      this.swiperRef?.nativeElement.swiper.slideNext();
    }
  }

  getRandom() {
    return Math.random().toString();
  }

  openContactDialog() {
    this.dialog.open(ContactModalComponent, {
      width: '40em',
    });
  }
  animationCreated(anim: import("lottie-web").AnimationItem): void {
    this.anim = anim;
  }

  complete() {
    this.anim.frame = 74;
    this.anim.playSegments([37, 120], true);
  }
}
