export const environment = {
  production: true,
  // serverEndpoint: 'https://appwrite.bugprog.studio/v1',
  // projectId: '640713dc179cfebafeca',
  // formsDatabaseId: '64350da3cdb7af8e2fbe',
  // contactCollectionId: '64350ddbc02f2da47cad',
  // reviewCollectionId: '64350dac61cc3917f3fa',
  // contactFunctionId: '642fcfd04687ddaca9ac',
  // avatarBucketId: '64350dfcb0cf3444bf9c'
  serverEndpoint: 'https://cloud.appwrite.io/v1',
  projectId: '6571f92e64fd5717d15f',
  formsDatabaseId: '642c2c67eb4100b9189e',
  contactCollectionId: '642c2c7e6cc45fe85697',
  reviewCollectionId: '642c2f50e2ac79094750',
  contactFunctionId: '655792758ddf3f969185',
  avatarBucketId: '64332c03202fda80154a',
  themeFunction: '65724177e1ccdf649925',
  assistantFunctionId: '65723d72da84553c757c',
  verifyThemeFunction: '65727b15e118e6553585',
};
