<div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
  <div class="col-12 mx-auto">
    <button
      (click)="openContactDialog()"
      class="p-4 w-100"
      color="primary"
      mat-flat-button>
      Etablir un devis
    </button>
  </div>
</div>
