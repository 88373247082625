<h2 mat-dialog-title>L'IA du Climat</h2>
<mat-dialog-content>
    <p>
      Ce site internet est un site interactif généré par intelligence artificielle....
    </p>
    <div class="d-flex justify-content-around gap-2 pt-3" style="cursor: pointer;">
      <div
        *ngFor="let theme of themes"
        class="d-flex flex-column gap-1 align-items-center"
        (click)="selectTheme(theme.slug)"
      >
        <img [src]="theme.image" draggable="false" width="150px" height="150px" class="rounded no-select" [ngClass]="{'not-selected': theme.slug !== selectedTheme, 'img-selected': theme.slug === selectedTheme}">
        <p>{{ theme.name}}</p>
      </div>
    </div>
  <div>

  <div class="mt-3">
    <p>Vous pouvez choisir un thème personnalisé d'un seul mot. <span class="fw-semibold">(Optionnel)</span></p>
    <div class="d-flex gap-2">
      <input
        class="form-control customthemeinput"
        [ngClass]="{ 'border-success': customThemeNiceState, 'border-danger': customThemeNotNiceState}"
        placeholder="permafrost"
        [(ngModel)]="customTheme"
      />
      <button
        mat-flat-button
        (click)="verifyCustomTheme()"
         [disabled]="customTheme.length === 0"
          color="primary"
        >
        Vérifier
      </button>
    </div>
  </div>
    <p *ngIf="customThemeNotNiceState" class="text-danger px-2 pt-1" style="font-size: 14px;">Désolé, nous ne pouvons pas générer votre site internet avec ce thème. Essayez-en un autre en rapport avec le climat !</p>
    <p *ngIf="customThemeNiceState" class="text-success px-2 pt-1" style="font-size: 14px;">
      Ce thème est génial ! Nous allons l'utiliser pour générer votre site internet
    </p>

    <div class="mt-4">
      <p>Vous pouvez choisir une langue</p>
      <mat-form-field class="w-100">
          <mat-label>Choisissez un langage</mat-label>
          <mat-select [(ngModel)]="selectedLang" >
            <mat-option *ngFor="let country of countries" [value]="country.name">
              <img loading="lazy" style="width: 20px" [src]="country.url" alt="">
              <span class="mx-3">{{ country.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>

  </div>
  <div class="d-flex justify-content-end mt-4">
    <button
        mat-flat-button
        (click)="onSubmit()" [disabled]="this.selectedLang.length <= 0 || this.selectedTheme.length <= 0"
          color="primary"
        >
        C'est parti !
      </button>
  </div>
</mat-dialog-content>
